import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../store/store';
import Settings from '../../components/header/Settings';
import { editUserAccount } from '../../store/features/user/userSlice';

interface SettingsContainerProps {
  onClose: () => void;
}

function SettingsContainer({ onClose }: SettingsContainerProps) {
  const dispatch = useDispatch<AppDispatch>();
  const defaultValue = useSelector(
    (state: RootState) => state.user.data?.accounts[0].compliance_sms,
  );

  const handleSave = (selectedValue: string) => {
    dispatch(editUserAccount(selectedValue));
  };

  return (
    <Settings
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      defaultValue={defaultValue!}
      onClose={onClose}
      onSave={handleSave}
    />
  );
}

export default SettingsContainer;
