import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../store/store';
import { Appointment as AppointmentObj, AppointmentData } from '../../store/features/appointments/types';

import AppointmentComponent from '../../components/appointment/Appointment';
import { UserData } from '../../store/features/user/types';
import { loadAppointments } from '../../store/features/appointments/appointmentsSlice';

interface AppointmentProps {
  user: UserData;
  searchTerm: string;
}

function Appointment({ user, searchTerm }: AppointmentProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { data, status } = useSelector((state: RootState) => state.appointment);
  const accountId = user.accounts[0].id;
  const userId = user?.id;

  useEffect(() => {
    dispatch(loadAppointments({ accountId, filter: searchTerm, userId }));
  }, [dispatch, userId, searchTerm, accountId]);

  const appointments = data?.map(
    (appointmentItem: AppointmentData) => new AppointmentObj(appointmentItem),
  );

  return (
    <AppointmentComponent
      appointments={appointments ?? null}
      status={status}
    />
  );
}

export default Appointment;
