import React from 'react';

import './todo-skeleton.css';

import Skeleton from '../../ui/skeleton/Skeleton';
import ListItem from '../../ui/list-item/ListItem';

interface SkeletonComponentProps {
  count: number;
}

function SkeletonComponent({ count }: SkeletonComponentProps) {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <ListItem key={index} className="skeleton-body">
      <div className="skeleton-info">
        <div className="skeleton-title skeleton-title-wrapper">
          <div className="skeleton-title">
            <Skeleton variant="text" width="10%" height={10} />
          </div>
          <div className="skeleton-title-2">
            <Skeleton variant="text" width="15%" height={10} />
          </div>
        </div>
        <div className="skeleton-list">
          <div className="skeleton-list-item">
            <div className="skeleton-checkbox" />
            <Skeleton variant="text" width="90%" height={10} />
          </div>
          <div className="skeleton-list-item">
            <div className="skeleton-checkbox" />
            <Skeleton variant="text" width="90%" height={10} />
          </div>
          <div className="skeleton-list-item">
            <div className="skeleton-checkbox" />
            <Skeleton variant="text" width="90%" height={10} />
          </div>
        </div>
      </div>
    </ListItem>
  ));

  return <div className="skeleton">{skeletons}</div>;
}

export default SkeletonComponent;
