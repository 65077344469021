import React, { useState } from 'react';

import AppointmentItem from '../AppointmentItem';
import AppointmentSkeleton from '../AppointmentSkeleton';
import List from '../../ui/list/List';
import LoadingComponent from '../../ui/ui-feedback/LoadingComponent';
import NoDataToShow from '../../ui/ui-feedback/NoDataToShow';
import { Status } from '../../../utils/constants';
import { Appointment as AppointmentType } from '../../../store/features/appointments/types';
import AppointmentModal from '../../../containers/AppointmentModal';

interface AppointmentProps {
  appointments: AppointmentType[] | null;
  status: string;
}

function Appointment({ appointments, status }: AppointmentProps) {
  const [previewAppointment, setPreviewAppointment] = useState<AppointmentType | null>(null);

  const hasData = appointments && appointments.length !== 0;
  const isLoading = !hasData && status === Status.Loading;
  const isRefreshing = hasData && status === Status.Loading;
  const isFailed = !isLoading && !isRefreshing && (status === Status.Failed || !hasData);

  const handleAppointmentPreview = (a: AppointmentType) => {
    setPreviewAppointment(a);
  };

  if (isLoading) {
    return <AppointmentSkeleton count={6} />;
  }

  if (isFailed) {
    return <NoDataToShow />;
  }

  return (
    <>
      {isRefreshing && <LoadingComponent />}
      <List>
        {appointments?.map((appointment: AppointmentType) => (
          <AppointmentItem
            appointment={appointment}
            key={appointment.id()}
            onClick={handleAppointmentPreview}
          />
        ))}
      </List>
      {previewAppointment && (
        <AppointmentModal
          appointment={previewAppointment}
          onClose={() => setPreviewAppointment(null)}
        />
      )}
    </>
  );
}

export default Appointment;
