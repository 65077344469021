import React from 'react';

function Todo() {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.542 0H10.458C3.906 0 0 3.906 0 10.458V25.542C0 32.094 3.906 36 10.458 36H25.542C32.094 36 36 32.094 36 25.542V10.458C36 3.906 32.094 0 25.542 0ZM14.346 23.22L10.296 27.27C10.026 27.54 9.684 27.666 9.342 27.666C9 27.666 8.64 27.54 8.388 27.27L7.038 25.92C6.498 25.398 6.498 24.534 7.038 24.012C7.56 23.49 8.406 23.49 8.946 24.012L9.342 24.408L12.438 21.312C12.96 20.79 13.806 20.79 14.346 21.312C14.868 21.834 14.868 22.698 14.346 23.22ZM14.346 10.62L10.296 14.67C10.026 14.94 9.684 15.066 9.342 15.066C9 15.066 8.64 14.94 8.388 14.67L7.038 13.32C6.498 12.798 6.498 11.934 7.038 11.412C7.56 10.89 8.406 10.89 8.946 11.412L9.342 11.808L12.438 8.712C12.96 8.19 13.806 8.19 14.346 8.712C14.868 9.234 14.868 10.098 14.346 10.62ZM28.008 26.316H18.558C17.82 26.316 17.208 25.704 17.208 24.966C17.208 24.228 17.82 23.616 18.558 23.616H28.008C28.764 23.616 29.358 24.228 29.358 24.966C29.358 25.704 28.764 26.316 28.008 26.316ZM28.008 13.716H18.558C17.82 13.716 17.208 13.104 17.208 12.366C17.208 11.628 17.82 11.016 18.558 11.016H28.008C28.764 11.016 29.358 11.628 29.358 12.366C29.358 13.104 28.764 13.716 28.008 13.716Z"
        fill="white"
      />
    </svg>
  );
}

export default Todo;
