import React from 'react';
import { useTranslation } from 'react-i18next';

import './appointment-item.css';
import ListItem from '../../ui/list-item/ListItem';
import Location from '../../ui/icons/Location/Location';
import { Appointment } from '../../../store/features/appointments/types';
import { firstLetterUpperCase } from '../../../utils/constants';

interface AppointmentItemProps {
  appointment: Appointment;
  isUsedInModal?: boolean;
  onClick: (a: Appointment) => void;
}

function AppointmentItem({
  appointment,
  isUsedInModal,
  onClick,
}: AppointmentItemProps) {
  const { t } = useTranslation();
  const [day, month] = appointment.dayAndMonthAbbreviation(t).split(' ');

  const handleKeyDown = (event: React.KeyboardEvent, a: Appointment) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick(a);
    }
  };

  return (
    <ListItem className="appointment-item">
      <span
        className="appointment-item__icon"
        onClick={() => onClick(appointment)}
        onKeyDown={(event) => handleKeyDown(event, appointment)}
        tabIndex={0}
        role="button"
      >
        <div className="appointment-item__date-container">
          <span className="appointment-item__date-day">{day}</span>
          <span className="appointment-item__date-month">{month}</span>
        </div>
      </span>
      <div
        className="appointment-item__info"
        onClick={() => onClick(appointment)}
        onKeyDown={(event) => handleKeyDown(event, appointment)}
        tabIndex={0}
        role="button"
      >
        {appointment.name() && <div>{appointment.name()}</div>}
        <div>
          <span className="appointment-item__general-info">
            {t('scheduled_at')}
            :
          </span>
          <span className="appointment-item__highlighted-info">
            {appointment.formattedFollowUpDate()}
          </span>
        </div>
        <p>{firstLetterUpperCase(appointment.sentence())}</p>
        {
          appointment.location()
          && appointment.location() !== ''
          && appointment.location() !== null
          && appointment.location() !== 'null'
          && (
            <span className="appointment-item__location">
              {t('location')}
              :
              <Location />
              <span>{appointment.location()}</span>
            </span>
          )
        }
      </div>
    </ListItem>
  );
}

AppointmentItem.defaultProps = {
  isUsedInModal: false,
};

export default AppointmentItem;
