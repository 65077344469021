import { TFunction } from 'i18next';
import { Status } from '../../../utils/constants';

export interface AppointmentData {
  id: string;
  name: string;
  sentence: string;
  meeting_id: string;
  meeting_name: string;
  meeting_start_time: string;
  receiver_id: string;
  receiver_name: string;
  session_id: string;
  start: number;
  end: number;
  created_at: string;
  follow_up_date: string;
  location: string | null;
}

export class Appointment {
  appointment: AppointmentData;

  constructor(appointment: AppointmentData) {
    this.appointment = appointment;
  }

  public id() {
    return this.appointment.id;
  }

  public name() {
    return this.appointment.name;
  }

  public sentence() {
    return this.appointment.sentence;
  }

  public meetingId() {
    return this.appointment.meeting_id;
  }

  public meetingName() {
    return this.appointment.meeting_name;
  }

  public meetingStartTime() {
    return this.appointment.meeting_start_time;
  }

  public receiverId() {
    return this.appointment.receiver_id;
  }

  public receiverName() {
    return this.appointment.receiver_name;
  }

  public sessionId() {
    return this.appointment.session_id;
  }

  public start() {
    return this.appointment.start;
  }

  public end() {
    return this.appointment.end;
  }

  public createdAt() {
    return this.appointment.created_at;
  }

  public followUpDate() {
    return this.appointment.follow_up_date;
  }

  public location() {
    return this.appointment.location;
  }

  public followUpDay(): string {
    const date = new Date(this.followUpDate());
    return String(date.getDate()).padStart(2, '0');
  }

  public formattedFollowUpDate(): string {
    const date = new Date(this.followUpDate());

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  public followUpDateOnly(): string {
    const date = new Date(this.followUpDate());

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  public dayAndMonthAbbreviation(t: TFunction): string {
    const date = new Date(this.followUpDate());
    const day = String(date.getDate()).padStart(2, '0');
    const monthAbbreviations = [
      t('jan'),
      t('feb'),
      t('mar'),
      t('apr'),
      t('may'),
      t('jun'),
      t('jul'),
      t('aug'),
      t('sep'),
      t('oct'),
      t('nov'),
      t('dec'),
    ];
    const monthAbbreviation = monthAbbreviations[date.getMonth()];

    return `${day} ${monthAbbreviation}`;
  }
}

export interface AppointmentState {
  data: AppointmentData[] | null;
  status: Status;
}
