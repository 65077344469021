import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PartnerState } from './data';
import getPartner from './partnerApi';
import { Status } from '../../../utils/constants';

const initialState: PartnerState = {
  data: null,
  status: Status.Idle,
};

export const loadPartner = createAsyncThunk(
  'appointments/loadPartner',
  async () => {
    const partnerID = process.env.REACT_APP_PARTNER_ID;
    if (typeof partnerID === 'undefined') return null;

    const response = await getPartner(partnerID);

    return response;
  },
);

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    updatePartner: (partnerState, action) => {
      partnerState.data = action.payload;
      partnerState.status = Status.Succeeded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPartner.pending, (partnerState) => {
        partnerState.status = Status.Loading;
      })
      .addCase(loadPartner.fulfilled, (partnerState, action) => {
        partnerState.status = Status.Succeeded;
        partnerState.data = action.payload;
      })
      .addCase(loadPartner.rejected, (partnerState) => {
        partnerState.status = Status.Failed;
      });
  },
});

export const { updatePartner } = partnerSlice.actions;

export default partnerSlice.reducer;
