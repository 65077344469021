import React from 'react';

interface IncomingCallProps {
  width?: string;
  height?: string;
  color?: string;
}

function IncomingCall({ width, height, color }: IncomingCallProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91075 0L11.8215 5.96577L5.91075 11.9315L4.44621 10.4534L7.85673 7.01111H-1.90735e-06V4.92044H7.85673L4.44621 1.47817L5.91075 0ZM23.1312 2.83793C23.6738 2.83793 24.2174 3.01753 24.6585 3.37693L24.707 3.41776L27.9496 6.80286L27.8829 6.73753C28.9576 7.67422 29.2505 9.15028 28.7891 10.3921C27.9207 12.9281 25.706 17.8319 21.337 22.2502C16.9588 26.6881 12.0622 28.8359 9.64088 29.7921L9.61863 29.8003L9.59435 29.8084C8.33972 30.2348 6.98248 29.9297 6.01189 29.0898L5.98155 29.0632L2.76118 25.8027C1.90493 24.9367 1.90425 23.4213 2.75916 22.5544L2.76118 22.5523L6.98286 18.2709H6.98488C7.84345 17.4026 9.34871 17.4026 10.2073 18.2709L12.2503 20.3392C12.9864 19.9832 14.7815 19.0674 16.4902 17.4175C18.1798 15.7862 19.042 13.8995 19.3546 13.1708L17.2933 11.0843C16.3865 10.1672 16.3342 8.59801 17.3803 7.75632L17.2852 7.84411L21.5575 3.41572L21.606 3.37693C22.0471 3.01753 22.5887 2.83793 23.1312 2.83793ZM23.1312 4.92044C23.0589 4.9203 22.9865 4.94787 22.9148 5.00414L18.7235 9.34883L18.6729 9.38966C18.6874 9.37797 18.6351 9.4819 18.7599 9.60812L21.8083 12.6931L21.5736 13.324C21.5736 13.324 20.4101 16.5264 17.9224 18.9284C15.4761 21.2905 12.4607 22.5789 12.4607 22.5789L11.8154 22.8586L8.74071 19.7471C8.56893 19.5733 8.6201 19.5758 8.45144 19.7471L4.22775 24.0285L4.22572 24.0305C4.05107 24.2071 4.05107 24.1499 4.22572 24.3266L7.3672 27.5054C7.84435 27.9158 8.33411 28.0238 8.92479 27.826C11.2555 26.9038 15.8178 24.8824 19.8704 20.774C23.9502 16.6481 26.0654 11.9605 26.8411 9.68979L26.8472 9.6755L26.8512 9.6612C27.0072 9.24466 26.8943 8.63766 26.5296 8.31982L26.4932 8.2892L23.3477 5.00414C23.276 4.94839 23.2036 4.92057 23.1312 4.92044Z"
        fill={color}
      />
    </svg>
  );
}

IncomingCall.defaultProps = {
  width: '30px',
  height: '30px',
  color: '#434343',
};

export default IncomingCall;
