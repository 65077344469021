import React from 'react';
import { useTranslation } from 'react-i18next';

import './conversation-todo.css';

import NoDataToShow from '../../../ui/ui-feedback/NoDataToShow/NoDataToShow';
import { Todo as ITodo } from '../../../../store/features/todos/types';
import List from '../../../ui/list/List';
import TodoItem from '../../../todo/ToDoItem';
import { customUuid } from '../../../../utils/constants';

interface ConversationTodoProps {
  todoData: ITodo[];
  onClick?: (todo: ITodo) => void;
}

function ConversationTodo({
  todoData,
  onClick,
}: ConversationTodoProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="conversation-todos__title">{t('todos')}</div>
      <div>
        {todoData.length !== 0 ? (
          <List className="conversation-todo__list">
            {todoData?.map((todo: ITodo) => (
              <TodoItem
                key={customUuid()}
                todo={todo}
                onTodoClick={() => onClick && onClick(todo)}
              />
            ))}
          </List>
        ) : (
          <NoDataToShow />
        )}
      </div>
    </>
  );
}

ConversationTodo.defaultProps = {
  onClick: () => {},
};

export default ConversationTodo;
