import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../ui/modal';
import Close from '../../ui/icons/Close/Close';

import './settings.css';

interface SettingsProps {
  defaultValue: string;
  onClose: () => void;
  onSave: (value: string) => void;
}

function Settings({ defaultValue, onClose, onSave }: SettingsProps) {
  const { t } = useTranslation();
  const options = [
    { label: t('compliance_option_1'), value: 'always' },
    { label: t('compliance_option_2'), value: 'on_confirm' },
    { label: t('compliance_option_3'), value: 'no' },
  ];

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>(t('save'));
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option.value === defaultValue)?.value || '',
  );

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleSave = async () => {
    setIsFormDisabled(true);
    setButtonText(t('saving'));

    try {
      await new Promise((resolve) => { setTimeout(resolve, 2000); });
      await onSave(selectedOption);

      setButtonText(t('saved'));
      setTimeout(onClose, 500);
    } catch (error) {
      console.error(error);
      setIsFormDisabled(false);
      setButtonText(t('save'));
    }
  };

  return (
    <Modal isOpen className="h-300">
      <div className="recording-detail__header">
        <button
          aria-label="close-modal"
          type="button"
          className="recording-detail__close-button"
          onClick={onClose}
        >
          <Close />
        </button>
        <h2 className="mt-0">{t('compliance')}</h2>
      </div>
      <div>
        <p>{t('compliance_description')}</p>
        <form>
          {options.map((option) => (
            <div key={option.value} className="input-wrapper">
              <div className="checkbox">
                <label className="cursor-pointer" htmlFor={option.value}>
                  <input
                    type="radio"
                    id={option.value}
                    name="compliancePreference"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={handleOptionChange}
                    disabled={isFormDisabled}
                  />
                  <span
                    className={`checkbox-icon ${
                      selectedOption === option.value ? 'checked' : ''
                    }`}
                  />
                  {option.label}
                </label>
              </div>
            </div>
          ))}
          <button type="button" disabled={isFormDisabled} className="button" onClick={handleSave}>
            {buttonText}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default Settings;
