import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchTodos, markTodoDone, markTodoUndone } from './todosApi';
import { TodoSimpleObject, TodosState } from './types';
import { Status } from '../../../utils/constants';
import { loadFullRecordingDetails } from '../recordings/actions';

const todosInitialState: TodosState = {
  data: null,
  status: Status.Idle,
};

export const loadTodos = createAsyncThunk(
  'todos/loadTodos',
  async ({ account, filter, userId }: { account: string; filter?: string; userId?: number }) => {
    const response = await fetchTodos(account, filter, userId);

    return response;
  },
);

export const markTodoAsDone = createAsyncThunk(
  'todos/markTodoAsDone',
  async ({ accountId, todo }: { accountId: string; todo: TodoSimpleObject }) => {
    await markTodoDone(accountId, todo.id);
    return todo;
  },
);

export const markTodoAsUndone = createAsyncThunk(
  'todos/markTodoAsUndone',
  async ({ accountId, todo }: { accountId: string; todo: TodoSimpleObject }) => {
    await markTodoUndone(accountId, todo.id);
    return todo;
  },
);

export const todosSlice = createSlice({
  name: 'todos',
  initialState: todosInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadTodos.pending, (todosState) => {
        todosState.status = Status.Loading;
      })
      .addCase(loadTodos.fulfilled, (todosState, action) => {
        todosState.status = Status.Succeeded;
        todosState.data = action.payload as unknown as TodoSimpleObject[];
      })
      .addCase(loadTodos.rejected, (todosState) => {
        todosState.status = Status.Failed;
        todosState.data = null;
      })
      .addCase(markTodoAsDone.fulfilled, (todosState, action) => {
        const todoObj = action.payload;
        todosState.data = (todosState.data || []).map((todo) => (
          todo.id === todoObj.id ? { ...todo, completed: true } : todo));
      })
      .addCase(markTodoAsUndone.fulfilled, (todosState, action) => {
        const todoObj = action.payload;
        todosState.data = (todosState.data || []).map((todo) => (
          todo.id === todoObj.id ? { ...todo, completed: false } : todo));
      })
      .addCase(loadFullRecordingDetails.pending, (todosState, action) => {
        todosState.status = Status.Loading;
      })
      .addCase(loadFullRecordingDetails.fulfilled, (todosState, action) => {
        todosState.status = Status.Succeeded;
      });
  },
});

export default todosSlice.reducer;
