import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppDispatch, RootState } from '../../store/store';
import PrivateMobileNumberComponent from '../../components/header/PrivateMobileNumber';
import ConfirmationModal from '../../components/ui/confirmation-modal';
import { PhoneNumberRecord } from '../../store/features/user/types';
import {
  addPhoneNumber,
  removePhoneNumber,
  getListOfPrivateNumbers,
} from '../../store/features/user/userSlice';
import { getMeetingCount } from '../../store/features/user/userAPI';
import { loadFullRecordingDetails } from '../../store/features/recordings/actions';
import { deleteMeetingUsingSessionId } from '../../store/features/recordings/recordingsAPI';
import Recording from '../../store/features/recordings/models/Recording';

interface PrivateMobileNumberProps {
  onClose: () => void;
  isUsedInRecordingModal?: boolean;
  recording?: Recording;
  closeRecordingModal?: () => void;
}

function PrivateMobileNumber({
  onClose,
  isUsedInRecordingModal,
  closeRecordingModal,
  recording,
}: PrivateMobileNumberProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const privateMobileNumbers: PhoneNumberRecord[] = useSelector(
    (state: RootState) => state.user.data?.private_phone_numbers || [],
  );
  const [meetingCount, setMeetingCount] = useState<number>(0);
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [recordingMobileNumber, setRecordingMobileNumber] = useState<string>('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const confirmationText = t('private_number_meeting_count', {
    count: meetingCount,
  });
  const accountId = useSelector(
    (state: RootState) => state.user.data?.accounts[0].id,
  );
  const userId = useSelector((state: RootState) => state.user.data?.id);

  useEffect(() => {
    dispatch(getListOfPrivateNumbers());
  }, [dispatch]);

  useEffect(() => {
    if (
      recording
      && recording.info
      && recording.info.participants
      && recording.info.participants.length > 0
      && recording.info.participants[0].phone
    ) {
      setRecordingMobileNumber(recording.info.participants[0].phone);
    }
  }, [recording]);

  const savePhone = (
    phoneNumber: string,
    flag: { forceDeleteExisting: boolean },
  ) => {
    dispatch(
      addPhoneNumber({
        phoneNumber,
        forceDeleteExisting: flag.forceDeleteExisting,
      }),
    );

    if (accountId && userId) {
      dispatch(loadFullRecordingDetails({ accountId, userId }));
    }

    setIsConfirmationModalOpen(false);
    setMobileNumber('');
  };

  const prepareSavePhone = useCallback((phoneNumber: string) => {
    setMobileNumber(phoneNumber);
  }, []);

  const deletePhone = useCallback(
    (phoneId: string) => {
      dispatch(removePhoneNumber(phoneId));
    },
    [dispatch],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (mobileNumber !== '') {
        const response = await getMeetingCount(mobileNumber);
        setMeetingCount(response.count);
        if (response.count > 1) {
          setIsConfirmationModalOpen(true);
        } else {
          savePhone(mobileNumber, { forceDeleteExisting: false });
        }
      }
    };

    fetchData();
  }, [mobileNumber]);

  const deleteSession = async () => {
    if (accountId && recording && userId && closeRecordingModal) {
      try {
        await deleteMeetingUsingSessionId(recording.sessionId, accountId);

        onClose();
        closeRecordingModal();
        dispatch(loadFullRecordingDetails({ accountId, userId }));
      } catch (error) {
        console.error('Failed to delete session:', error);
      }
    }
  };

  const addNumberToListAndDeleteCall = (flag: boolean) => {
    deleteSession();
    setIsConfirmationModalOpen(false);
    closeRecordingModal?.();
    savePhone(recordingMobileNumber, { forceDeleteExisting: flag });
  };

  const deleteSessionAndAddNumberToTheList = async () => {
    if (recordingMobileNumber !== '') {
      const response = await getMeetingCount(recordingMobileNumber);
      setMeetingCount(response.count);

      if (response.count > 1) {
        setIsConfirmationModalOpen(true);
      } else {
        addNumberToListAndDeleteCall(false);
      }
    }
  };

  return (
    <>
      {!isUsedInRecordingModal ? (
        <PrivateMobileNumberComponent
          privateMobileNumbers={privateMobileNumbers}
          onClose={onClose}
          prepareSavePhone={prepareSavePhone}
          deletePrivateMobileNumber={deletePhone}
        />
      ) : (
        <ConfirmationModal
          title={t('confirm_delete')}
          isOpen={isUsedInRecordingModal}
          text={t('delete_session_description')}
          buttonOneText={t('remove_this_session_and_add_number_to_list')}
          buttonTwoText={t('remove_this_session')}
          confirmAction={deleteSessionAndAddNumberToTheList}
          cancelAction={deleteSession}
          closeModal={onClose}
        />
      )}

      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={isUsedInRecordingModal ? recordingMobileNumber : mobileNumber}
          closeModal={() => setIsConfirmationModalOpen(false)}
          isOpen={isConfirmationModalOpen}
          text={confirmationText}
          confirmAction={() => (
            isUsedInRecordingModal
              ? addNumberToListAndDeleteCall(true)
              : savePhone(mobileNumber, { forceDeleteExisting: true })
          )}
          cancelAction={() => (
            isUsedInRecordingModal
              ? addNumberToListAndDeleteCall(false)
              : savePhone(mobileNumber, { forceDeleteExisting: false })
          )}
        />
      )}
    </>
  );
}

PrivateMobileNumber.defaultProps = {
  isUsedInRecordingModal: false,
  recording: {},
  closeRecordingModal: () => {},
};

export default PrivateMobileNumber;
