import axios from 'axios';

const acceptFlowURL = 'https://switchboard.acceptflow.com';
const acceptStackURL = 'https://switchboard.acceptstack.com';
const meetricURL = 'https://switchboard.meetric.com';

const baseURL = () => {
  switch (window.location.origin) {
    case acceptFlowURL:
      return 'https://api.acceptflow.com/api';
    case acceptStackURL:
      return 'https://api.acceptstack.com/api';
    case meetricURL:
      return 'https://api.meetric.com/api';
    default:
      return process.env.REACT_APP_API_BASE_URL;
  }
};

const axiosInstance = axios.create({
  baseURL: `${baseURL()}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*',
  },
});

function getCookie(name: string) {
  if (!document.cookie) {
    return null;
  }

  const cookieName = `${name}=`;
  const cookieArray = document.cookie.split(';');

  let foundCookieValue: string | null = null;
  cookieArray.forEach((cookie) => {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.indexOf(cookieName) === 0 && foundCookieValue === null) {
      foundCookieValue = decodeURIComponent(
        trimmedCookie.substring(cookieName.length),
      );
    }
  });

  return foundCookieValue;
}

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getCookie('JWT_Token');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
