import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

import { customUuid } from '../../../utils/constants';

import Replay from '../../ui/icons/Replay/Replay';
import NoDataToShow from '../../ui/ui-feedback/NoDataToShow';

import './recording-call-player.css';

import RecordingTranscript from '../RecordingTranscript';
import { TranscriptInfo } from '../../../store/features/recordings/models/types';
import RecordingTruncatedTranscript from '../RecordingTruncatedTranscript';
import Recording from '../../../store/features/recordings/models/Recording';
import { PersonData } from '../../../store/features/recordings/data/PersonData';
import RecordingPlayerFallback from './RecordingPlayerFallback';

interface RecordingPlayerProps {
  url: string | null;
  fullTranscript: TranscriptInfo[];
  isMenuOpen: boolean;
  startTime?: number;
  endTime?: number;
  recording: Recording;
}

function RecordingPlayer({
  url,
  fullTranscript,
  isMenuOpen,
  startTime,
  endTime,
  recording,
}: RecordingPlayerProps) {
  const playerRef = useRef<ReactPlayer>(null);
  const transcriptRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { t } = useTranslation();

  const [currentTranscript, setCurrentTranscript] = useState<TranscriptInfo | null>(
    fullTranscript.length > 0 ? fullTranscript[0] : null,
  );
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const scrollToTranscript = (index: number) => {
    const transcriptRef = transcriptRefs.current[index];
    const containerRef = document.querySelector('.recording-transcript__transcript-preview') as HTMLElement;
    if (transcriptRef && containerRef) {
      const offset = transcriptRef.offsetTop - containerRef.offsetTop - (window.innerHeight / 5);
      containerRef.scrollTo({ top: offset, behavior: 'smooth' });
    }
  };

  const findClosestTranscriptIndex = (currentTime: number) => {
    const foundIndex = fullTranscript.reduce((
      closestIndex,
      currentItem,
      currentIndex,
    ) => {
      const { start, end } = currentItem;
      const startDifference = Math.abs(currentTime - start);
      const endDifference = Math.abs(currentTime - end);

      if (startDifference < Math.abs(currentTime - fullTranscript[closestIndex].start)) {
        return currentIndex;
      }
      if (endDifference < Math.abs(currentTime - fullTranscript[closestIndex].end)) {
        return currentIndex;
      }

      return closestIndex;
    }, 0);

    return foundIndex;
  };

  useEffect(() => {
    const onTimeUpdate = (progress: { playedSeconds: number }) => {
      const currentTime = progress.playedSeconds;

      const closestTranscriptIndex = findClosestTranscriptIndex(currentTime);

      transcriptRefs.current.forEach((ref, index) => {
        if (ref) {
          ref.classList.add('hidden');

          if (index === closestTranscriptIndex) {
            ref.classList.add('active-transcript');
            scrollToTranscript(index);
          } else {
            ref.classList.remove('active-transcript');
          }
        }
      });

      if (endTime && currentTime > endTime) {
        setIsPlaying(false);
      }
    };

    if (playerRef.current) {
      playerRef.current.seekTo(0); // Seek to the beginning when component mounts
    }

    // Highlight first active sentence when currentTime is 0
    onTimeUpdate({ playedSeconds: 0 });

    return () => {
      // Cleanup function
      if (playerRef.current) {
        playerRef.current.seekTo(0); // Seek to the beginning when component unmounts
      }
    };
  }, [fullTranscript]);

  useEffect(() => {
    if (isPlayerReady && startTime && playerRef.current) {
      playerRef.current.seekTo(startTime, 'seconds');
    }
  }, [isPlayerReady, startTime]);

  const handleProgress = ({ playedSeconds }: { playedSeconds: number }) => {
    const currentTime = playedSeconds;
    const activeTranscriptIndexes: number[] = [];
    let closestTranscriptIndex = -1;
    let closestTimeDifference = Infinity;

    fullTranscript.forEach((item, index) => {
      if (item.start <= currentTime && item.end >= currentTime) {
        activeTranscriptIndexes.push(index);
      } else {
        const startDifference = Math.abs(currentTime - item.start);

        if (startDifference < closestTimeDifference) {
          closestTimeDifference = startDifference;
          closestTranscriptIndex = index;
        }
      }
    });

    if (!isMenuOpen) {
      setCurrentTranscript(fullTranscript[closestTranscriptIndex]);
    }

    if (closestTranscriptIndex !== -1 && activeTranscriptIndexes.length === 0) {
      activeTranscriptIndexes.push(closestTranscriptIndex);
    }

    transcriptRefs.current.forEach((ref, index) => {
      if (ref) {
        if (activeTranscriptIndexes.includes(index)) {
          ref.classList.add('active-transcript');
          scrollToTranscript(index);
        } else {
          ref.classList.remove('active-transcript');
        }
      }
    });

    if (endTime && currentTime > endTime) {
      setIsPlaying(false);
    }
  };

  const handleReady = () => {
    setIsPlayerReady(true);
  };

  function handleReplay() {
    if (playerRef.current && startTime && endTime) {
      playerRef.current.seekTo(startTime, 'seconds');
      setIsPlaying(true);
      setTimeout(() => {
        setIsPlaying(false);
      }, (endTime - startTime) * 1000);
    }
  }

  const handleSeekToTime = useCallback((time: number) => {
    if (playerRef.current) {
      playerRef.current.seekTo(time, 'seconds');
    }
  }, []);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleSeek = (seconds: number) => {
    const foundIndex = findClosestTranscriptIndex(seconds);

    scrollToTranscript(foundIndex);
  };

  const speakerDetails = recording.info.participants[0];

  let speakerName = t('unknown_person');
  if (speakerDetails.first_name && speakerDetails.last_name) {
    speakerName = `${speakerDetails.first_name} ${speakerDetails.last_name}`;
  } else if (speakerDetails.email) {
    speakerName = speakerDetails.email;
  } else if (speakerDetails.phone) {
    speakerName = speakerDetails.phone;
  }

  const person: PersonData = {
    id: speakerDetails.id,
    name: speakerName,
  };

  return (
    <div className="recording-call-player">
      {!url ? (
        <NoDataToShow text={t('call_no_audio')} />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '5px',
            alignItems: 'center',
          }}
        >
          {endTime && startTime && (
            <div>
              <button
                type="button"
                className="recording-call-player__replay-button"
                onClick={() => handleReplay()}
              >
                <span style={{ display: 'none' }}>Replay</span>
                <Replay />
              </button>
            </div>
          )}
          <ReactPlayer
            ref={playerRef}
            url={url}
            controls
            width="100%"
            height={50}
            fallback={<RecordingPlayerFallback />}
            playing={isPlaying}
            className="recording-call-player__audio-player"
            config={{
              file: {
                forceAudio: true,
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
            onProgress={handleProgress}
            onReady={handleReady}
            onPlay={handlePlay}
            onPause={handlePause}
            onSeek={handleSeek}
          />
        </div>
      )}

      <div className="border-line" style={{ background: 'black', height: '1px' }} />

      {fullTranscript.length === 0 ? (
        <NoDataToShow text={t('call_no_transcript')} />
      ) : (
        <div
          className={`recording-transcript__transcript-preview ${
            isMenuOpen && !endTime ? 'mt-45' : ''
          }`}
        >
          {!isMenuOpen ? (
            <RecordingTruncatedTranscript
              transcript={currentTranscript ?? fullTranscript[0]}
              person={person}
            />
          ) : (
            fullTranscript.map((transcriptItem, index) => (
              <RecordingTranscript
                key={customUuid()}
                ref={(ref) => {
                  transcriptRefs.current[index] = ref;
                }}
                transcript={transcriptItem}
                onSeekToTime={handleSeekToTime}
                person={person}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
}

RecordingPlayer.defaultProps = {
  startTime: undefined,
  endTime: undefined,
};

export default RecordingPlayer;
