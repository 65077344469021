import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TodoModalItem from './TodoModalItem';
import Modal from '../../ui/modal';
import RecordingPlayer from '../../conversation/RecordingPlayer';
import RecordingDetailsHeader from '../../conversation/RecordingDetailsHeader';
import CloseButton from '../../ui/button/CloseButton';

import Recording from '../../../store/features/recordings/models/Recording';
import { Todo } from '../../../store/features/todos/types';
import { TranscriptInfo } from '../../../store/features/recordings/models/types';
import { CallDirections } from '../../../utils/constants';

import './todo-modal.css';

interface TodoModalProps {
  todo: Todo;
  recording: Recording;
  onClose: () => void;
}

function TodoModal({
  todo,
  recording,
  onClose,
}: TodoModalProps) {
  const { t } = useTranslation();

  const isCallIncoming = recording.info.call_direction === CallDirections.Incoming;

  const getDisplayName = (name: string) => {
    const search = 'call with';

    if (name.toLowerCase().includes(search)) {
      return name.replace(new RegExp(search, 'gi'), t('call_with'));
    }

    return name;
  };

  const incomingOrOutgoingCall = recording.info.call_direction
  && recording.info.call_direction === 'incoming'
    ? t('incoming')
    : t('outgoing');

  const partialTranscript = useMemo(() => recording.transcriptDetails?.transcript.filter(
    (item: TranscriptInfo) => item.start >= todo.start && item.end <= todo.end,
  ) ?? [], [recording.transcriptDetails, todo.start, todo.end]);

  const callUrl = useMemo(() => recording.urlInfo?.url, [recording.urlInfo]);

  const callTodo = useMemo(() => todo, [todo]);

  return (
    <Modal
      isOpen
      align="center"
    >
      <>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CloseButton onClick={onClose} />
        </div>
        <div className="todo-wrapper">
          <TodoModalItem todo={callTodo} />
        </div>
        <RecordingDetailsHeader
          recordingMeetingTime={recording.info.meeting_time}
          recordingName={getDisplayName(recording.info.name)}
          isCallIncoming={isCallIncoming}
          callDirection={incomingOrOutgoingCall}
          recordingDate={recording.info.date}
        />
        <div>
          <RecordingPlayer
            fullTranscript={partialTranscript}
            isMenuOpen
            url={callUrl ?? null}
            startTime={callTodo.start}
            endTime={callTodo.end}
            recording={recording}
          />
        </div>
      </>
    </Modal>
  );
}

export default TodoModal;
