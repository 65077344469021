import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../store/store';
import {
  markTodoAsUndone,
  markTodoAsDone as markTodoAsDoneAction,
} from '../store/features/todos/todosSlice';
import { Todo } from '../store/features/todos/types';

interface UseMarkTodoAsDoneProps {
  todo: Todo;
  markAsDone: boolean;
}

function useMarkTodoAsDone({ todo, markAsDone }: UseMarkTodoAsDoneProps) {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.data);
  const accountId = user?.accounts[0]?.id;

  const markTodoAsDone = () => new Promise((resolve, reject) => {
    if (accountId) {
      const todoData = todo.toData();

      if (markAsDone) {
        dispatch(markTodoAsDoneAction({ accountId, todo: todoData }))
          .then(resolve)
          .catch(reject);
      } else {
        dispatch(markTodoAsUndone({ accountId, todo: todoData }))
          .then(resolve)
          .catch(reject);
      }
    } else {
      reject(new Error('Account ID not found'));
    }
  });

  return markTodoAsDone;
}

export default useMarkTodoAsDone;
