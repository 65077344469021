import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './password-reset-form.css';

import Input from '../../ui/input';
import Button from '../../ui/button';
import LinkButton from '../../ui/link-button';

interface PasswordResetFormProps {
  emailForReset: string;
  resetStatus: {
    success: boolean;
    message: string;
  };
  setEmailForReset: (email: string) => void;
  onSubmit: () => void;
  onBackToLogin: () => void;
  clearResetStatus: () => void;
  setResetStatus: (status: { success: boolean; message: string }) => void;
}

function PasswordResetForm({
  emailForReset,
  resetStatus,
  setEmailForReset,
  onSubmit,
  onBackToLogin,
  clearResetStatus,
  setResetStatus,
}: PasswordResetFormProps) {
  const { t } = useTranslation();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailForReset(e.target.value);
    clearResetStatus();
  };

  const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

  const handleSubmit = async () => {
    clearResetStatus();

    if (!emailForReset || !validateEmail(emailForReset)) {
      setResetStatus({
        success: false,
        message: t('email_validation_error'),
      });

      return;
    }
    setIsFormDisabled(true);
    try {
      await onSubmit();
    } catch (error) {
      console.error(error);
    } finally {
      setIsFormDisabled(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="reset-password-form">
      <div className="reset-password-form__container">
        <h1 className="reset-password-form__title">{t('reset_password')}</h1>
        <div className="input-wrapper">
          {!resetStatus.success && (
            <Input
              type="email"
              isRequired
              value={emailForReset}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              placeholder={t('enter_email')}
              disabled={isFormDisabled}
            />
          )}
          {resetStatus.message && (
            <div
              className={resetStatus.success ? 'success-message' : 'error-text'}
            >
              {resetStatus.message}
            </div>
          )}
        </div>
        {!resetStatus.success && (
          <Button
            type="button"
            handleClick={handleSubmit}
            description={isFormDisabled ? t('resetting') : t('reset_password')}
          />
        )}
        <LinkButton
          type="button"
          description={t('back_to_login_page')}
          handleClick={onBackToLogin}
        />
      </div>
    </div>
  );
}

export default PasswordResetForm;
