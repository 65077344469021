import React from 'react';

function Menu() {
  return (
    <svg
      width="30"
      height="21"
      viewBox="0 0 30 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="3" rx="1.5" fill="#333333" />
      <rect x="7.5" y="9" width="22.5" height="3" rx="1.5" fill="#333333" />
      <rect y="18" width="30" height="3" rx="1.5" fill="#333333" />
    </svg>
  );
}

export default Menu;
