import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './login.css';

import Button from '../../ui/button';
import Input from '../../ui/input';
import LinkButton from '../../ui/link-button';
import { RootState } from '../../../store/store';
import { clearErrorMessage } from '../../../store/features/user/userSlice';

interface LoginComponentProps {
  onLogin: (username: string, password: string) => void;
  onPasswordForgot: () => void;
}

function LoginComponent({ onLogin, onPasswordForgot }: LoginComponentProps) {
  const dispatch = useDispatch();
  const clearGlobalErrorMessage = () => dispatch(clearErrorMessage());
  const errorMessage = useSelector(
    (state: RootState) => state.user.errorMessage,
  );
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [errors, setErrors] = useState({ username: '', password: '' });
  const { t } = useTranslation();

  const validateForm = () => {
    let isValid = true;
    const newErrors = { username: '', password: '' };

    if (!username || !/\S+@\S+\.\S+/.test(username)) {
      newErrors.username = t('email_validation_error');
      isValid = false;
    }

    if (password.length === 0) {
      newErrors.password = t('password_validation_error');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleFormSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      setIsFormDisabled(true);
      try {
        await onLogin(username, password);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFormDisabled(false);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleFormSubmit();
    }
  };

  const handleClick = () => {
    handleFormSubmit();
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div>
          <h1 className="login-title">{t('login')}</h1>
          <form className="form-content">
            <div className="input-wrapper">
              <Input
                type="text"
                placeholder={t('email')}
                value={username}
                isRequired
                onChange={(e) => {
                  clearGlobalErrorMessage();
                  setErrors({ username: '', password: '' });
                  setUsername(e.target.value);
                }}
                onKeyDown={handleKeyPress}
                disabled={isFormDisabled}
              />
              {errors.username && (
                <div id="username-error" className="error-text">
                  {errors.username}
                </div>
              )}
            </div>
            <div className="input-wrapper">
              <Input
                type={showPassword ? 'text' : 'password'}
                placeholder={t('password')}
                value={password}
                isRequired
                onChange={(e) => {
                  clearGlobalErrorMessage();
                  setErrors({ username: '', password: '' });
                  setPassword(e.target.value);
                }}
                onKeyDown={handleKeyPress}
                disabled={isFormDisabled}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="show-password-button"
                disabled={isFormDisabled}
              >
                {showPassword ? t('hide') : t('show')}
              </button>
              {errors.password && (
                <div id="password-error" className="error-text">
                  {errors.password}
                </div>
              )}
            </div>
          </form>

          {errorMessage?.error && (
            <div id="login-error" className="error-message">
              {errorMessage.error?.error}
            </div>
          )}
        </div>
        <div className="text-center">
          <Button
            type="button"
            handleClick={handleClick}
            description={isFormDisabled ? t('logging') : t('login')}
          />
          <LinkButton
            type="button"
            description={t('forgot_password')}
            handleClick={onPasswordForgot}
          />
        </div>
      </div>
    </div>
  );
}

export default LoginComponent;
