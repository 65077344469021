import React from 'react';
import { useTranslation } from 'react-i18next';

import './header.css';

import SearchBar from '../../form/SearchBar';
import MenuDropdown from '../../../../containers/MenuDropdown';

interface HeaderProps {
  value: string;
  partnerIcon?: string;
  onChange: (value: string) => void;
  onProfileClick: () => void;
  onSettingsClick: () => void;
  onPrivateMobileNumberClick: () => void;
}

function Header({
  value,
  partnerIcon,
  onChange,
  onProfileClick,
  onSettingsClick,
  onPrivateMobileNumberClick,
}: HeaderProps) {
  const { t } = useTranslation();

  return (
    <header className="header-main">
      <div className="container">
        <div className="top-row">
          {partnerIcon && (
            <img
              src={partnerIcon}
              alt="Partner icon"
              width={40}
              height={40}
              className="header__partner-icon"
            />
          )}
          <h6 className="header__title">{t('header_title')}</h6>
          <MenuDropdown
            onProfileClick={onProfileClick}
            onSettingsClick={onSettingsClick}
            onPrivateMobileNumberClick={onPrivateMobileNumberClick}
          />
        </div>
        <SearchBar value={value} onChange={onChange} />
      </div>
    </header>
  );
}

Header.defaultProps = {
  partnerIcon: null,
};

export default Header;
