import React from 'react';
import { Todo } from '../../store/features/todos/types';
import TodoListItem from '../../components/todo/ToDoItem';
import { customUuid } from '../../utils/constants';

interface TodoItemProps {
  todo: Todo;
  onTodoClick: (todo: Todo) => void;
}

function TodoItem({ todo, onTodoClick }: TodoItemProps) {
  const handleClick = () => {
    onTodoClick(todo);
  };

  return (
    <TodoListItem
      key={customUuid()}
      todo={todo}
      onTodoClick={handleClick}
    />
  );
}

export default TodoItem;
