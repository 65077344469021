import React from 'react';

import './skeleton-component.css';

import Skeleton from '../../ui/skeleton';
import ListItem from '../../ui/list-item/ListItem';

interface SkeletonComponentProps {
  count: number;
}

function SkeletonComponent({ count }: SkeletonComponentProps) {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <ListItem key={index} className="skeleton-body">
      <span className="skeleton__icons">
        <Skeleton variant="not-circular" width="55" height={75} />
      </span>
      <div className="skeleton__info">
        <div className="skeleton__item">
          <Skeleton variant="text" width="30%" height={10} />
        </div>
        <div className="skeleton__item">
          <Skeleton variant="text" width="60%" height={10} />
        </div>
        <div className="skeleton__item">
          <Skeleton variant="text" width="40%" height={40} />
        </div>
        <div className="skeleton-list-item">
          <div className="skeleton-checkbox" />
          <Skeleton variant="text" width="70%" height={10} />
        </div>
        <div className="skeleton-list-item">
          <div className="skeleton-checkbox" />
          <Skeleton variant="text" width="70%" height={10} />
        </div>
      </div>
    </ListItem>
  ));

  return <div className="skeleton">{skeletons}</div>;
}

export default SkeletonComponent;
