import React, { ReactNode } from 'react';

import './list.css';

interface ListProps {
  className?: string;
  children?: ReactNode;
}

function CustomList({ className, children }: ListProps) {
  const listClass = `custom-list ${className || ''}`.trim();

  return (
    <ul className={listClass}>
      {children}
    </ul>
  );
}

CustomList.defaultProps = {
  className: '',
  children: true,
};

export default CustomList;
