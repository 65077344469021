import React from 'react';

import './todo-modal-item.css';

import CheckboxIcon from '../../../ui/icons/Checkbox/Checkbox';

import useMarkTodoAsDone from '../../../../hooks/useMarkTodoAsDone';
import { Todo } from '../../../../store/features/todos/types';
import { firstLetterUpperCase } from '../../../../utils/constants';

interface TodoModalItemProps {
  todo: Todo;
}

function TodoModalItem({
  todo,
}: TodoModalItemProps) {
  const markTodo = useMarkTodoAsDone({ todo, markAsDone: !todo.completed });

  const handleCheckboxClick = () => {
    markTodo();
  };

  return (
    <div className="todo-modal-item">
      <div className="todo-modal-item__task">
        <div className="todo-modal-item__checkmark">
          <span style={{ display: 'none' }}>Checkmark</span>
          <CheckboxIcon
            width="30"
            height="30"
            done={todo.completed}
            onClick={handleCheckboxClick}
          />
        </div>
        <span className="todo-modal-sentence">{firstLetterUpperCase(todo.sentence)}</span>
      </div>
    </div>
  );
}

export default TodoModalItem;
