import React, { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import './search-bar.css';

import withDebounce from '../DebounceHOC/DebounceHoc';
import MagnifyingGlass from '../../icons/MagnifyingGlass/MagnifyingGlass';

interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

function SearchBar({
  value,
  onChange,
  placeholder,
  onKeyPress,
}: SearchBarProps) {
  const { t } = useTranslation();
  const defaultPlaceholder = t('search_bar');

  return (
    <div className="search-bar-container">
      <MagnifyingGlass className="search-bar-icon" />
      <input
        className="search-bar"
        placeholder={placeholder || defaultPlaceholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyPress}
      />
    </div>
  );
}

SearchBar.defaultProps = {
  placeholder: '',
  onKeyPress: () => undefined,
};

export default withDebounce(SearchBar, 2000);
