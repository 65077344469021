import { useEffect } from 'react';

const usePartnerIcon = (icon?: string) => {
  useEffect(() => {
    if (icon) {
      let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }

      link.href = icon;
      document.head.appendChild(link);
    }

    return undefined;
  }, [icon]);
};

export default usePartnerIcon;
