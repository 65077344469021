import React, {
  useState,
  useEffect,
  ComponentType,
  KeyboardEvent,
} from 'react';

interface WithDebounceProps {
  onChange: (value: string) => void;
  value: string;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

function withDebounce<P extends WithDebounceProps>(
  Component: ComponentType<P>,
  wait = 2000,
) {
  function DebouncedComponent(props: P) {
    const { value, onChange, onKeyPress } = props;
    const [bufferedValue, setBufferedValue] = useState(value);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const handleOnChange = (newValue: string) => {
      if (timer) clearTimeout(timer);
      setBufferedValue(newValue);
      setTimer(
        setTimeout(() => {
          onChange(newValue);
        }, wait),
      );
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        if (timer) clearTimeout(timer);
        onChange(bufferedValue);
      }

      if (onKeyPress) {
        onKeyPress(event);
      }
    };

    useEffect(
      () => () => {
        if (timer) clearTimeout(timer);
      },
      [timer],
    );

    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        value={bufferedValue}
        onChange={handleOnChange}
        onKeyPress={handleKeyPress}
      />
    );
  }

  DebouncedComponent.displayName = `WithDebounce(${Component.displayName || Component.name})`;
  return DebouncedComponent;
}

export default withDebounce;
