import React from 'react';
import Close from '../../icons/Close/Close';

import './close-button.css';

interface CloseButtonProps {
  onClick: () => void;
  className?: string;
}

function CloseButton({ onClick, className }: CloseButtonProps) {
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={`close-button ${className}`}
      onClick={onClick}
    >
      <Close />
    </div>
  );
}

CloseButton.defaultProps = {
  className: '',
};

export default CloseButton;
