import React from 'react';

import './loader-dots.css';

function LoaderDots() {
  return (
    <div className="loader-dots">
      <span className="loader-dots__dot">.</span>
      <span className="loader-dots__dot">.</span>
      <span className="loader-dots__dot">.</span>
      <span className="loader-dots__dot">.</span>
    </div>
  );
}

export default LoaderDots;
