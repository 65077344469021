import { AxiosResponse } from 'axios';

import {
  Account,
  PhoneNumberRecord,
  UserResponse,
} from './types';
import axiosInstance from '../../../utils/axiosInstance';

export const fetchUserData = () => axiosInstance.get<UserResponse | null>('me', {
  headers: {},
});

export const loginUser = (
  username: string,
  password: string,
): Promise<AxiosResponse<UserResponse>> => axiosInstance.post<UserResponse>('/login', {
  userName: username,
  password,
});

export const resetPassword = async (email: string) => {
  try {
    const response = await axiosInstance.post<void>('reset-password', {
      email,
    });

    return response;
  } catch (e: any) {
    return e.message;
  }
};

export const editProfile = async (
  userId: number,
  profileData: {
    username: string;
    firstName: string;
    lastName: string;
    personalEmail: string;
    mobileNo: string;
    emailAliases: string[];
  },
) => {
  const payload = {
    email: profileData.personalEmail,
    email_aliases: profileData.emailAliases,
    first_name: profileData.firstName,
    integration: '',
    last_name: profileData.lastName,
    mobile_number: profileData.mobileNo,
    username: profileData.username,
  };

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axiosInstance.put(
      `/users/${userId}`,
      payload,
    );
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const editAccount = async (accountId: string, accountData: Account) => {
  const payload = {
    ...accountData,
    compliance_sms: accountData.compliance_sms,
  };

  try {
    const response = await axiosInstance.put(`/accounts/${accountId}`, payload);
    return response.data;
  } catch (e: any) {
    throw new Error(e.response.data.message || e.message);
  }
};

export const getListOfPrivateMobileNumbers = async () => {
  try {
    const response = await axiosInstance.get('private-phone-numbers');
    return response.data;
  } catch (e: any) {
    throw new Error(e.response.data.message || e.message);
  }
};

export const getMeetingCount = async (phoneNumber: string) => {
  try {
    const response = await axiosInstance.get(`private-phone-numbers/meeting-count?phone_number=${phoneNumber}`);
    return response.data;
  } catch (e: any) {
    throw new Error(e.response.data.message || e.message);
  }
};

export const postPhoneNumber = async (
  phoneNumber: string,
  forceDeleteExisting: boolean,
): Promise<PhoneNumberRecord> => {
  const payload = {
    phone_number: phoneNumber,
    force_delete_phone_number_sessions: forceDeleteExisting,
  };

  try {
    const response = await axiosInstance.post('private-phone-numbers', payload);
    return response.data;
  } catch (e: any) {
    throw new Error(e.response.data.message || e.message);
  }
};

export const deletePhoneNumber = async (phoneNumberId: string) => {
  try {
    const response = await axiosInstance.delete(`private-phone-numbers/${phoneNumberId}`);
    return response.data;
  } catch (e: any) {
    throw new Error(e.response.data.message || e.message);
  }
};
