import React, { useEffect } from 'react';

import './modal.css';

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  align?: string;
  className?: string;
}

function Modal({
  isOpen,
  children,
  align,
  className,
}: ModalProps) {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={`custom-modal-backdrop ${align || null}`}>
      <div className={`custom-modal ${className || ''}`}>
        {children}
      </div>
    </div>
  );
}

Modal.defaultProps = {
  className: '',
  align: '',
};

export default Modal;
