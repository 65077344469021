import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PrivateMobileNumber from '../PrivateMobileNumber';
import { AppDispatch, RootState } from '../../store/store';
import Recording from '../../store/features/recordings/models/Recording';
import RecordingDetailView from '../../components/conversation/RecordingDetail';
import LoadingComponent from '../../components/ui/ui-feedback/LoadingComponent';
import { Status, SUMMARY_OPENED } from '../../utils/constants';
import {
  loadRecordingTranscript,
  loadRecordingUrl,
  loadSwitchCallPrivacyType,
} from '../../store/features/recordings/actions';
import { SummaryData } from '../../store/features/recordings/types';
import { Todo, TodoSimpleObject } from '../../store/features/todos/types';
import { loadAppointments } from '../../store/features/appointments/appointmentsSlice';
import { Appointment, AppointmentData } from '../../store/features/appointments/types';

interface RecordingDetailContainerProps {
  recording: Recording;
  closeModal: () => void;
  startTime?: number;
  onAppointmentClick?: (appointment: Appointment) => void;
  onTodoClick?: (todo: Todo) => void;
}

function RecordingDetailContainer({
  recording,
  closeModal,
  startTime,
  onAppointmentClick,
  onTodoClick,
}: RecordingDetailContainerProps) {
  const [activeTab, setActiveTab] = useState(SUMMARY_OPENED);
  const [summaryData, setSummaryData] = useState<SummaryData | null>(null);
  const [appointmentData, setAppointmentData] = useState<Appointment[] | null>(null);
  const [todoData, setTodoData] = useState<Todo[] | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.data);
  const accountId = useMemo(() => user?.accounts[0]?.id ?? '', [user?.accounts]);

  const {
    status,
    transcriptStatus,
    todoStatus,
    urlStatus,
    appointmentStatus,
  } = useSelector((state: RootState) => state.recordings);

  const [urlLoaded, setUrlLoaded] = useState(false);
  const [todosLoaded, setTodosLoaded] = useState(false);
  const [transcriptLoaded, setTranscriptionLoaded] = useState(false);
  const [appointmentsLoaded, setAppointmentsLoaded] = useState(false);
  const [isOpenPrivateMobileNumber, setIsOpenPrivateMobileNumber] = useState(false);

  const handleSwitchCallType = () => {
    dispatch(loadSwitchCallPrivacyType({ sessionId: recording.sessionId }));
  };

  useEffect(() => {
    if (!recording.info.id) return;

    if (!transcriptLoaded && !recording.transcriptDetails && transcriptStatus !== Status.Loading) {
      dispatch(loadRecordingTranscript({ sessionId: recording.sessionId }));
      setTranscriptionLoaded(true);
    } else if (recording.transcriptDetails) {
      const { summary } = recording.transcriptDetails;

      if (summary) {
        setSummaryData(new SummaryData(
          summary.session_id,
          summary.summary,
          summary.seller_questions,
          summary.buyer_questions,
          summary.objections,
          summary.deal_probability_reason,
          summary.deal_probability_percent,
          summary.summary_dashboard ?? '',
          summary.summary_switchboard ?? '',
        ));
      }
    }

    if (!urlLoaded && !recording.urlInfo && urlStatus !== Status.Loading) {
      dispatch(loadRecordingUrl({ sessionId: recording.sessionId }));
      setUrlLoaded(true);
    }

    if (!todosLoaded && recording.todos.length < 1) {
      setTodosLoaded(true);
    } else {
      setTodoData(recording.todos.map((t: TodoSimpleObject) => new Todo(t)));
    }

    if (!appointmentsLoaded && !recording.appointments) {
      dispatch(loadAppointments({ accountId, sessionId: recording.sessionId }));
      setAppointmentsLoaded(true);
    } else if (recording.appointments && recording.appointments.length > 0) {
      setAppointmentData(recording.appointments.map(
        (appointmentDataItem: AppointmentData) => new Appointment(appointmentDataItem),
      ));
    }
  }, [
    dispatch,
    accountId,
    recording,
    transcriptLoaded,
    urlLoaded,
    todosLoaded,
    appointmentsLoaded,
  ]);

  const onClickDelete = () => {
    setIsOpenPrivateMobileNumber(true);
  };

  const closePrivateMobileNumber = () => {
    setIsOpenPrivateMobileNumber(false);
  };

  const isLoading = useMemo(
    () => [status, transcriptStatus, todoStatus, urlStatus, appointmentStatus].some(
      (s) => s !== Status.Succeeded,
    ),
    [recording, status, transcriptStatus, todoStatus, urlStatus, appointmentStatus],
  );

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <RecordingDetailView
        activeTab={activeTab}
        recording={recording}
        summaryData={summaryData}
        appointmentData={appointmentData}
        todoData={todoData}
        transcriptData={recording.transcriptDetails?.transcript ?? []}
        setActiveTab={setActiveTab}
        closeModal={closeModal}
        startTime={startTime ?? undefined}
        onSwitchCallType={handleSwitchCallType}
        onTodoClick={onTodoClick}
        onAppointmentClick={onAppointmentClick}
        onClickDelete={onClickDelete}
      />
      {isOpenPrivateMobileNumber && (
        <PrivateMobileNumber
          onClose={closePrivateMobileNumber}
          closeRecordingModal={closeModal}
          recording={recording}
          isUsedInRecordingModal
        />
      )}
    </>
  );
}

RecordingDetailContainer.defaultProps = {
  startTime: 0,
  onAppointmentClick: () => {},
  onTodoClick: () => {},
};

export default RecordingDetailContainer;
