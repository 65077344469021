import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import fetchAppointments from './appointmentsAPI';
import { AppointmentState } from './types';
import { Status } from '../../../utils/constants';
import { loadFullRecordingDetails } from '../recordings/actions';

const initialState: AppointmentState = {
  data: null,
  status: Status.Idle,
};

export const loadAppointments = createAsyncThunk(
  'appointments/loadAppointments',
  async ({
    accountId,
    filter,
    userId,
    sessionId,
  }: {
    accountId: string;
    filter?: string;
    userId?: number;
    sessionId?: string;
  }) => {
    const response = await fetchAppointments(
      accountId,
      filter,
      userId,
      sessionId,
    );

    return response;
  },
);

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAppointments.pending, (appointmentState) => {
        appointmentState.status = Status.Loading;
      })
      .addCase(loadAppointments.fulfilled, (appointmentState, action) => {
        appointmentState.status = Status.Succeeded;
        appointmentState.data = action.payload;
      })
      .addCase(loadAppointments.rejected, (appointmentState) => {
        appointmentState.status = Status.Failed;
      })
      .addCase(loadFullRecordingDetails.pending, (appointmentState, action) => {
        appointmentState.status = Status.Loading;
      })
      .addCase(loadFullRecordingDetails.fulfilled, (appointmentState, action) => {
        appointmentState.status = Status.Succeeded;
      });
  },
});

export default appointmentsSlice.reducer;
