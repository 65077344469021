import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchRecordingBySession,
  fetchRecordings,
  fetchRecordingTranscript,
  fetchTodosBySession,
  switchCallPrivacyType,
} from './recordingsAPI';
import { RecordingsState } from './types';
import Recording from './models/Recording';
import fetchAppointments from '../appointments/appointmentsAPI';
import { fetchTodos } from '../todos/todosApi';

export const loadRecordings = createAsyncThunk(
  'recordings/loadRecordings',
  async ({ accountId, filter, userId }: {
    accountId: string;
    filter?: string;
    userId?: number;
  }) => {
    const response = await fetchRecordings(accountId, filter, userId);

    return response;
  },
);

export const loadFullRecordingDetails = createAsyncThunk(
  'recordings/loadFullRecordingDetails',
  async ({
    accountId,
    filter,
    userId,
    sessionId,
  }: {
    accountId: string;
    filter?: string;
    userId?: number;
    sessionId?: string;
  }) => {
    const recordingPromise = fetchRecordings(accountId, filter, userId, sessionId);
    const appointmentPromise = fetchAppointments(accountId, filter, userId, sessionId);
    const todoPromise = fetchTodos(accountId, filter, userId, sessionId);

    return Promise.all([
      recordingPromise,
      appointmentPromise,
      todoPromise,
    ]).then(
      ([recordings, appointments, todos]) => ({
        recordings,
        appointments,
        todos,
      }),
    );
  },
);

export const loadRecordingTranscript = createAsyncThunk(
  'recordings/loadRecordingTranscript',
  async ({ sessionId }: { sessionId: string }) => {
    const response = await fetchRecordingTranscript(sessionId);

    return response;
  },
);

export const loadRecordingTodos = createAsyncThunk(
  'recordings/loadRecordingTodos',
  async ({ accountId, sessionId }: { accountId: string; sessionId: string }) => {
    const response = await fetchTodosBySession(accountId, sessionId);

    return response;
  },
);

export const loadRecordingUrl = createAsyncThunk(
  'recordings/loadRecordingUrl',
  async ({ sessionId }: { sessionId: string }) => {
    const response = await fetchRecordingBySession(sessionId);

    return response;
  },
);

export const loadSwitchCallPrivacyType = createAsyncThunk(
  'recordings/loadSwitchCallPrivacyType',
  async ({ sessionId }: { sessionId: string; }) => {
    await switchCallPrivacyType(sessionId);
  },
);

export const getRecordingBySessionId = (
  state: RecordingsState,
  sessionId: string,
): Recording | undefined => state.data[sessionId];
