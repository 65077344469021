import React from 'react';

import './skeleton-component.css';

import Skeleton from '../../ui/skeleton';
import ListItem from '../../ui/list-item/ListItem';

interface SkeletonComponentProps {
  count: number;
}

function SkeletonComponent({ count }: SkeletonComponentProps) {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <ListItem key={index} className="skeleton-body">
      <Skeleton variant="circular" width="75px" height={75} />
      <div className="skeleton__info">
        <Skeleton variant="text" width="70%" height={10} />
        <Skeleton variant="text" width="60%" height={10} />
        <Skeleton variant="text" width="90%" height={10} />
      </div>
    </ListItem>
  ));

  return <div className="skeleton">{skeletons}</div>;
}

export default SkeletonComponent;
