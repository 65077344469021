import { TFunction } from 'i18next';

export enum Status {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export enum CallDirections {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export const firstLetterUpperCase = (word: string): string => {
  if (word.length === 0) {
    return word;
  }

  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const SUMMARY_OPENED = 0;
export const TODO_OPENED = 1;
export const APPOINTMENT_OPENED = 2;

export const generateHashKey = (str: string) => {
  let hash = 0;

  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = (hash * 32) - hash + char;
  }

  return hash;
};

export const customUuid = (): string => {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.floor(Math.random() * 16);
    const v = c === 'x' ? r : ((r % 4) + 8);
    return v.toString(16);
  });
  return uuid;
};

export function fromNow(dateInput: string, t: TFunction): string {
  const date = new Date(dateInput);
  if (Number.isNaN(date.getTime())) {
    return t('invalid_date');
  }

  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  const days = Math.floor(seconds / 86400);

  if (days > 30) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60);

  if (days >= 1 && days <= 30) {
    return days === 1 ? t('one_day_ago') : `${days} ${t('days_ago')}`;
  }

  if (hours >= 1 && hours <= 23) {
    return hours === 1 ? t('one_hour_ago') : `${hours} ${t('hrs_ago')}`;
  }

  if (minutes >= 1 && minutes <= 59) {
    return minutes === 1 ? t('one_min_ago') : `${minutes}${t('mins_ago')}`;
  }

  if (seconds >= 0 && seconds <= 59) {
    return t('sec_ago');
  }

  return t('some_time_ago');
}
