import React from 'react';
import { useTranslation } from 'react-i18next';

import './recording-modal-header.css';

import IncomingCall from '../../ui/icons/IncomingCall/IncomingCall';
import OutgoingCall from '../../ui/icons/OutgoingCall/OutgoingCall';
import { firstLetterUpperCase } from '../../../utils/constants';
import CloseButton from '../../ui/button/CloseButton';

interface RecordingModalHeaderProps {
  recordingMeetingTime: number;
  recordingName: string;
  recordingDate: string;
  isCallIncoming: boolean;
  callDirection: string;
  recordingTopic?: string;
  onClickDelete: () => void;
  closeModal: () => void;
}

function RecordingModalHeader({
  recordingMeetingTime,
  recordingName,
  recordingDate,
  recordingTopic,
  isCallIncoming,
  callDirection,
  onClickDelete,
  closeModal,
}: RecordingModalHeaderProps) {
  const { t } = useTranslation();

  return (
    <div className="recording-modal-header__header">
      <CloseButton onClick={closeModal} />
      <div className="recording-modal-header__card-info">
        <button className="recording-modal-header__avatar" type="button">
          {isCallIncoming ? (
            <IncomingCall color="#00C26B" />
          ) : (
            <OutgoingCall width="20px" height="20px" color="#00C26B" />
          )}
        </button>
        <div className="recording-modal-header__info-wrapper">
          <div className="recording-modal-header__title">{recordingName}</div>
          <div className="recording-modal-header__date-and-button">
            <div className="recording-modal-header__info">
              {firstLetterUpperCase(callDirection)}
              <span className="recording-modal-header__info-date">{recordingDate}</span>
            </div>
            {/*
            TEMPORARILY HIDDEN
            <button
              className="recording-modal-header__delete-button"
              type="button"
              onClick={onClickDelete}
            >
              {t('delete')}
            </button> */}
          </div>
          <div className="recording-modal-header__info-description">
            {recordingTopic || ''}
          </div>
        </div>
      </div>
    </div>
  );
}

RecordingModalHeader.defaultProps = {
  recordingTopic: null,
};

export default RecordingModalHeader;
