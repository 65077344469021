import React from 'react';

interface CheckboxProps {
  width: string;
  height: string;
  done: boolean;
  onClick?: () => void;
}

function Checkbox({
  width, height, done, onClick,
}: CheckboxProps) {
  return done ? (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      className="checkbox-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      stroke="#00C26B"
    >
      <rect
        x="4"
        y="4"
        width="440"
        height="504"
        fill="#E6FFF4"
        rx="20"
        ry="20"
      />
      <path
        fill="#00C26B"
        d="M337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      className="checkbox-icon checked"
      viewBox="0 0 448 512"
    >
      <path
        fill="#e7e1e1"
        d="M384 80c8.8 0 16 7.2 16 16v320c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16h320zM64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
      />
    </svg>
  );
}

Checkbox.defaultProps = {
  onClick: () => {},
};

export default Checkbox;
