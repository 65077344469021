import React from 'react';

import './conversation-appointment.css';

import Location from '../../../ui/icons/Location/Location';
import { Appointment } from '../../../../store/features/appointments/types';
import { customUuid, firstLetterUpperCase } from '../../../../utils/constants';

interface ConversationAppointmentProps {
  appointments: Appointment[];
  onClick: (appointment: Appointment) => void;
}

function ConversationAppointment({
  appointments,
  onClick,
}: ConversationAppointmentProps) {
  return (
    <div className="conversation-appointment">
      {appointments?.map((appointment: Appointment) => (
        <div key={customUuid()} className="conversation-appointment-list">
          <div className="conversation-appointment-list__day">{`${appointment.followUpDay()}`}</div>
          <div className="conversation-appointment-list__info">
            <span className="conversation-appointment-list__date">
              {`${appointment.followUpDateOnly()}`}
            </span>
            {' '}
            <span
              className="conversation-appointment-list__sentence"
              onClick={() => onClick(appointment)}
              onKeyDown={(event) => {
                if (event.key === 'non-existent-key' && onClick) {
                  onClick(appointment);
                }
              }}
              tabIndex={0}
              role="button"
            >
              {firstLetterUpperCase(appointment.sentence())}
              <br />
              {
                appointment.location() !== ''
                && appointment.location() !== null
                && appointment.location() !== 'null'
                && (
                  <span style={{ display: 'flex', gap: '3px' }}>
                    <Location />
                    <span>{appointment.location()}</span>
                  </span>
                )
              }
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ConversationAppointment;
