import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppDispatch, RootState } from '../../store/store';
import Profile from '../../components/header/Profile';
import { editUserProfile } from '../../store/features/user/userSlice';

interface ProfileContainerProps {
  onClose: () => void;
}

function ProfileContainer({ onClose }: ProfileContainerProps) {
  const user = useSelector((state: RootState) => state.user.data);
  const dispatch = useDispatch<AppDispatch>();
  const [localError, setLocalError] = useState<string | null>(null);
  const { t } = useTranslation();

  const saveProfileData = async (profileData: {
    username: string;
    firstName: string;
    lastName: string;
    personalEmail: string;
    mobileNo: string;
    emailAliases: string[];
  }, onSuccess: () => void) => {
    if (!user || !user.id) {
      return;
    }

    try {
      setLocalError(null);

      await dispatch(editUserProfile({ userId: user.id, profileData })).unwrap();

      setTimeout(() => {
        onSuccess();
      }, 500);
    } catch (rejectedValueOrSerializedError: any) {
      const errorResponse = rejectedValueOrSerializedError?.errors ?? {};
      const firstErrorKey = Object.keys(errorResponse)[0];
      let firstErrorMessage = errorResponse[firstErrorKey];

      if (firstErrorMessage === 'This value is not a valid email address.') {
        firstErrorMessage = t('invalid_email_address');
      }

      setLocalError(firstErrorMessage || t('error_occurred'));
    }
  };

  return (
    <Profile
      errorMessages={localError}
      userData={user}
      onClose={onClose}
      onSave={(profileData) => saveProfileData(profileData, onClose)}
    />
  );
}

export default ProfileContainer;
