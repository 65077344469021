import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './private-mobile-number.css';

import Modal from '../../ui/modal';
import Input from '../../ui/input';
import Close from '../../ui/icons/Close/Close';
import { PhoneNumberRecord } from '../../../store/features/user/types';
import List from '../../ui/list/List';
import ListItem from '../../ui/list-item/ListItem';
import ConfirmationModal from '../../ui/confirmation-modal';
import NoDataToShow from '../../ui/ui-feedback/NoDataToShow';

interface PrivateMobileNumberProps {
  privateMobileNumbers: PhoneNumberRecord[];
  onClose: () => void;
  deletePrivateMobileNumber: (privatePhoneNumberId: string) => void;
  prepareSavePhone: (phoneNumber: string) => void;
}

function PrivateMobileNumber({
  privateMobileNumbers,
  prepareSavePhone,
  deletePrivateMobileNumber,
  onClose,
}: PrivateMobileNumberProps) {
  const { t } = useTranslation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [inputError, setInputError] = useState<string>('');
  const [selectedIdForDeletion, setSelectedIdForDeletion] = useState<
  string | null
  >(null);
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>(t('add_to_private_number_list'));

  const handleMobileNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMobileNumber(e.target.value);
    setInputError('');
  };

  const handleDeleteClick = (id: string) => {
    setSelectedIdForDeletion(id);
    setIsConfirmationModalOpen(true);
  };

  const confirmDelete = () => {
    if (selectedIdForDeletion) {
      deletePrivateMobileNumber(selectedIdForDeletion);
      setIsConfirmationModalOpen(false);
    }
  };

  const cancelDelete = () => {
    setIsConfirmationModalOpen(false);
    setSelectedIdForDeletion('');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (mobileNumber.trim() === '') {
      setInputError(t('number_field_error'));
      return;
    }

    setIsFormDisabled(true);
    setButtonText(t('saving'));

    try {
      await prepareSavePhone(mobileNumber);
      setButtonText(t('saved'));
      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error: any) {
      setInputError(error.message || t('something-went-wrong'));
      setIsFormDisabled(false);
      setButtonText(t('add_to_private_number_list'));
    }
  };

  return (
    <>
      <Modal isOpen className="h-300">
        <div className="recording-detail__header">
          <button
            aria-label="close-modal"
            type="button"
            className="recording-detail__close-button"
            onClick={onClose}
          >
            <Close />
          </button>
          <h2 className="mt-0">{t('private_mobile_numbers')}</h2>
        </div>
        <p className="description">{t('private_numbers_description')}</p>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="private-number-label">
              {t('add_private_number')}
            </div>
            <Input
              type="text"
              value={mobileNumber}
              isRequired={false}
              onChange={handleMobileNumberChange}
              className={`mobile-number-input ${
                inputError ? 'input-error' : ''
              }`}
              disabled={isFormDisabled}
            />
            {inputError && <div className="error-message">{inputError}</div>}
            <button type="submit" className="button" disabled={isFormDisabled}>
              {buttonText}
            </button>
          </form>
        </div>
        <div className="mobile-number-list-title">
          {t('list_of_private_mobile_numbers')}
        </div>
        {privateMobileNumbers.length === 0 ? (
          <NoDataToShow />
        ) : (
          <List className="mobile-number-list">
            {privateMobileNumbers.map((number) => (
              <ListItem className="mobile-number-list-item" key={number.id}>
                {number.phone_number}
                <button
                  className="delete-number-button"
                  type="button"
                  onClick={() => handleDeleteClick(number.id)}
                >
                  X
                </button>
              </ListItem>
            ))}
          </List>
        )}
      </Modal>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={mobileNumber}
          isOpen={isConfirmationModalOpen}
          text={t('delete_private_number')}
          confirmAction={confirmDelete}
          cancelAction={cancelDelete}
          closeModal={cancelDelete}
        />
      )}
    </>
  );
}

export default PrivateMobileNumber;
