import React from 'react';
import { useTranslation } from 'react-i18next';
import './confirmation-modal.css';
import Modal from '../modal';
import CloseButton from '../button/CloseButton';

interface ConfirmationModalProps {
  isOpen: boolean;
  text: string;
  title: string;
  buttonOneText?: string;
  buttonTwoText?: string;
  confirmAction: () => void;
  cancelAction: () => void;
  closeModal?: () => void;
}

function ConfirmationModal({
  isOpen,
  text,
  title,
  buttonOneText,
  buttonTwoText,
  confirmAction,
  cancelAction,
  closeModal = () => {},
}: ConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} className="confirmation-modal">
      <div className="confirmation-modal-header">
        <div className="confirmation-modal-header__title">{title}</div>
        <CloseButton className="close-button" onClick={closeModal} />
      </div>
      <div className="text-align">{text}</div>
      <div className="confirmation-modal-footer">
        <button
          type="button"
          className="confirm-button"
          onClick={confirmAction}
        >
          {buttonOneText || t('yes')}
        </button>
        <button type="button" className="cancel-button" onClick={cancelAction}>
          {buttonTwoText || t('no')}
        </button>
      </div>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  buttonOneText: '',
  buttonTwoText: '',
  closeModal: () => {},
};

export default ConfirmationModal;
