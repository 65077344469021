import React from 'react';

interface OutgoingCallProps {
  width?: string;
  height?: string;
  color?: string;
}

function OutgoingCall({ width, height, color }: OutgoingCallProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9388 2.52445C15.4475 2.52445 15.8599 2.94829 15.8599 3.47112V6.31115H18.623C19.1317 6.31115 19.5441 6.735 19.5441 7.25783C19.5441 7.78066 19.1317 8.2045 18.623 8.2045H15.0827C14.4946 8.2045 14.0178 7.71441 14.0178 7.10987V3.47112C14.0178 2.94829 14.4301 2.52445 14.9388 2.52445Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3947 0C17.9034 0 18.3157 0.423846 18.3157 0.946676V3.7867H21.0789C21.5876 3.7867 22 4.21055 22 4.73338C22 5.25621 21.5876 5.68006 21.0789 5.68006H17.5386C16.9505 5.68006 16.4736 5.18998 16.4736 4.58542V0.946676C16.4736 0.423846 16.886 0 17.3947 0Z"
        fill={color}
      />
      <path
        d="M7.33962 4.66656L5.39972 1.27314C5.02056 0.609873 4.19776 0.383264 3.58216 0.820818C2.37093 1.68172 0.565749 3.19205 0.116679 4.80683C-0.580426 7.31346 1.93254 13.2831 5.34908 16.7946C8.76572 20.3064 14.2775 22.5845 16.7163 21.8683C18.2873 21.4069 19.757 19.5522 20.5951 18.3077C21.021 17.6749 20.8007 16.8289 20.1552 16.4392L16.8535 14.4452C16.2272 14.067 15.4227 14.2537 15.0153 14.872L14.1076 16.2496C13.7149 16.8459 12.9523 17.0371 12.3654 16.6439C11.4203 16.0104 9.94902 14.9127 8.41653 13.3375C6.88418 11.7626 5.81625 10.2507 5.19992 9.27914C4.81716 8.67579 5.00348 7.89182 5.58386 7.48818L6.924 6.55612C7.5258 6.13756 7.70773 5.31048 7.33962 4.66656Z"
        fill={color}
      />
    </svg>
  );
}

OutgoingCall.defaultProps = {
  width: '30px',
  height: '30px',
  color: '#434343',
};

export default OutgoingCall;
