import React from 'react';

import './linear-progress.css';

function CustomLinearProgress() {
  return (
    <div className="linear-progress">
      <div className="progress-bar" />
    </div>
  );
}

export default CustomLinearProgress;
