import React from 'react';
import { useTranslation } from 'react-i18next';

import './recording-item-card.css';
import IncomingCall from '../../ui/icons/IncomingCall/IncomingCall';
import OutgoingCall from '../../ui/icons/OutgoingCall/OutgoingCall';

import ListItem from '../../ui/list-item/ListItem';
import {
  CallDirections,
  fromNow,
} from '../../../utils/constants';
import Recording from '../../../store/features/recordings/models/Recording';
import TodoItem from '../../todo/ToDoItem';
import { Todo as TodoType } from '../../../store/features/todos/types';
import { Appointment } from '../../../store/features/appointments/types';
import ConversationAppointment from '../RecordingDetail/ConversationAppointment';

interface RecordingItemCardProps {
  recording: Recording;
  onPreviewRecording: (recording: Recording) => void;
  onTodoClick: (todo: TodoType) => void;
  onAppointmentClick: (appointment: Appointment) => void;
}

function RecordingItemCard({
  recording,
  onPreviewRecording,
  onTodoClick,
  onAppointmentClick,
}: RecordingItemCardProps) {
  const {
    name,
    date,
    topic,
    private: privateMeeting,
  } = recording.info;
  const { t } = useTranslation();
  const PRIVATE_MEETING = `(${t('private')})`;

  const isCallIncoming = recording.info.call_direction === CallDirections.Incoming;
  const todos = recording.todos.map((todo) => new TodoType(todo));
  const appointments = recording.appointments.map((app) => new Appointment(app));

  const getDisplayName = (text: string) => {
    const search = 'call with';

    if (text.toLowerCase().includes(search)) {
      return text.replace(new RegExp(search, 'gi'), t('call_with'));
    }

    return text;
  };

  return (
    <div className={`wrapper ${recording.info.private ? 'private-call' : ''}`}>
      <ListItem className="recording-item-card">
        <button
          className="recording-item-card__button"
          type="button"
          onClick={() => onPreviewRecording(recording)}
        >
          <span className="recording-item-card__avatar">
            {isCallIncoming ? <IncomingCall color="#00C26B" /> : <OutgoingCall color="#00C26B" />}
            <span className="recording-item-card__duration">
              {Math.floor(recording.info.meeting_time / 60)}
              min
            </span>
          </span>
        </button>
        <div className="recording-item-card__info-wrapper">
          <div className="recording-item-card__date">{fromNow(date, t)}</div>
          <div
            className="recording-item-card__name"
            onClick={() => onPreviewRecording(recording)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onPreviewRecording(recording);
              }
            }}
            role="button"
            tabIndex={0}
          >
            {`${getDisplayName(name)} ${privateMeeting ? PRIVATE_MEETING : ''}`}
          </div>
          <div className="recording-item-card_info-description">
            {(!privateMeeting && topic) ? topic : ''}
          </div>
          {!recording.info.private && todos && todos.length > 0 && (
            <ul className="recording-item-card__todos">
              {todos
                .slice(0, 2)
                .map((todo) => (
                  <TodoItem
                    key={todo.id}
                    todo={todo}
                    onTodoClick={() => onTodoClick(todo)}
                  />
                ))}
            </ul>
          )}
          {!recording.info.private && appointments && appointments.length > 0 && (
            <ConversationAppointment
              appointments={appointments}
              onClick={onAppointmentClick}
            />
          )}
        </div>
      </ListItem>
    </div>
  );
}

export default RecordingItemCard;
