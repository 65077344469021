import { Status } from '../../../utils/constants';

export interface TodosState {
  data: TodoSimpleObject[] | null;
  status: Status;
}

export interface TodoSimpleObject {
  id: string;
  session_id: string;
  sentence: string;
  start: number;
  end: number;
  meeting_id: string;
  meeting_name: string;
  receiver_id: string;
  receiver_name: string;
  meeting_start_time: string;
  created_at: string;
  completed: boolean;
  importance: number | null;
}

export class Todo {
  id: string;

  session_id: string;

  sentence: string;

  start: number;

  end: number;

  meetingId: string;

  meetingName: string;

  receiverId: string;

  receiverName: string;

  meetingStartTime: string;

  createdAt: string;

  completed: boolean;

  importance: number | null;

  constructor(todo: TodoSimpleObject) {
    this.id = todo.id;
    this.session_id = todo.session_id;
    this.sentence = todo.sentence;
    this.start = todo.start;
    this.end = todo.end;
    this.meetingId = todo.meeting_id;
    this.meetingName = todo.meeting_name;
    this.receiverId = todo.receiver_id;
    this.receiverName = todo.receiver_name;
    this.meetingStartTime = todo.meeting_start_time;
    this.createdAt = todo.created_at;
    this.completed = todo.completed;
    this.importance = todo.importance;
  }

  formatDateTime(): string {
    const d = new Date(this.meetingStartTime);
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const day = `0${d.getDate()}`.slice(-2);
    const hours = `0${d.getHours()}`.slice(-2);
    const minutes = `0${d.getMinutes()}`.slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  public toData(): TodoSimpleObject {
    return {
      completed: this.completed,
      created_at: this.createdAt,
      id: this.id,
      meeting_id: this.meetingId,
      meeting_name: this.meetingName,
      meeting_start_time: this.meetingStartTime,
      receiver_id: this.receiverId,
      receiver_name: this.receiverName,
      sentence: this.sentence,
      session_id: this.session_id,
      start: this.start,
      end: this.end,
      importance: this.importance,
    };
  }
}
