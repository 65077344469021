import { createSlice, Draft } from '@reduxjs/toolkit';

import { RecordingsState } from './types';
import { Status } from '../../../utils/constants';
import { Info as RecordingInfo, RecordingUrlInfo, TranscriptDetails } from './models/types';
import Recording from './models/Recording';
import {
  loadFullRecordingDetails,
  loadRecordingTranscript,
  loadRecordingUrl,
  loadSwitchCallPrivacyType,
} from './actions';
import { markTodoAsDone, markTodoAsUndone } from '../todos/todosSlice';

const initialState: RecordingsState = {
  data: {},
  status: Status.Idle,
  todoStatus: Status.Idle,
  appointmentStatus: Status.Idle,
  transcriptStatus: Status.Idle,
  urlStatus: Status.Idle,
};

type Payload =
  | TranscriptDetails // return type of loadRecordingTranscript
  | RecordingUrlInfo // return type of loadRecordingUrl
  | null; // generic return type

const updateDataBySessionId = (
  recordingsState: Draft<RecordingsState>,
  sessionId: string,
  prop: keyof Recording,
  payload: Payload,
) => {
  const data = { ...recordingsState.data };

  if (sessionId in data) {
    data[sessionId] = {
      ...data[sessionId],
      [prop]: payload,
    };
  }

  recordingsState.data = data;
  recordingsState.status = Status.Succeeded;
};

export const recordingsSlice = createSlice({
  name: 'recordings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadFullRecordingDetails.fulfilled, (recordingsState, action) => {
        action.payload.recordings.forEach((info: RecordingInfo) => {
          recordingsState.data[info.session_id] = {
            sessionId: info.session_id,
            info,
            transcriptDetails: null,
            todos: [],
            appointments: [],
            urlInfo: null,
          };
        });

        action.payload.appointments.forEach((appointment) => {
          const recording = recordingsState.data[appointment.session_id];
          if (recording) {
            if (!recording.appointments.some(
              (existingAppointment) => existingAppointment.id === appointment.id,
            )) {
              recording.appointments.push(appointment);
            }
          }
        });

        recordingsState.appointmentStatus = Status.Succeeded;

        action.payload.todos.forEach((todo) => {
          const recording = recordingsState.data[todo.session_id];
          if (recording) {
            if (!recording.todos.some((existingTodo) => existingTodo.id === todo.id)) {
              recording.todos.push(todo);
            }
          }
        });

        recordingsState.todoStatus = Status.Succeeded;
        recordingsState.status = Status.Succeeded;
      })
      .addCase(loadFullRecordingDetails.pending, (recordingsState) => {
        recordingsState.status = Status.Loading;
      })
      .addCase(loadRecordingTranscript.pending, (recordingsState) => {
        recordingsState.transcriptStatus = Status.Loading;
      })
      .addCase(
        loadRecordingTranscript.fulfilled,
        (recordingsState, action) => {
          const { sessionId } = action.meta.arg;
          updateDataBySessionId(recordingsState, sessionId, 'transcriptDetails', action.payload ?? null);

          recordingsState.transcriptStatus = Status.Succeeded;
        },
      )
      .addCase(loadRecordingTranscript.rejected, (recordingsState) => {
        recordingsState.transcriptStatus = Status.Failed;
      })
      .addCase(loadRecordingUrl.pending, (recordingsState) => {
        recordingsState.urlStatus = Status.Loading;
      })
      .addCase(
        loadRecordingUrl.fulfilled,
        (recordingsState, action) => {
          const { sessionId } = action.meta.arg;
          updateDataBySessionId(recordingsState, sessionId, 'urlInfo', action.payload);

          recordingsState.urlStatus = Status.Succeeded;
        },
      )
      .addCase(loadRecordingUrl.rejected, (recordingsState) => {
        recordingsState.urlStatus = Status.Failed;
      })
      .addCase(markTodoAsDone.fulfilled, (recordingsState, action) => {
        const todoObj = action.payload;

        const recording = recordingsState.data[todoObj.session_id];
        if (recording) {
          const todoIndex = recording.todos.findIndex((todo) => todo.id === todoObj.id);
          if (todoIndex !== -1) {
            recording.todos[todoIndex].completed = true;
          } else {
            recording.todos.push(todoObj);
          }
        }
      })
      .addCase(markTodoAsUndone.fulfilled, (recordingsState, action) => {
        const todoObj = action.payload;

        const recording = recordingsState.data[todoObj.session_id];
        if (recording) {
          const todoIndex = recording.todos.findIndex((todo) => todo.id === todoObj.id);
          if (todoIndex !== -1) {
            recording.todos[todoIndex].completed = false;
          }
        }
      })
      .addCase(loadSwitchCallPrivacyType.fulfilled, (recordingsState, action) => {
        recordingsState.appointmentStatus = Status.Succeeded;

        const { sessionId } = action.meta.arg;
        const recording = recordingsState.data[sessionId];

        if (recording) {
          recording.info.private = false;
        }
      });
  },
});

export default recordingsSlice.reducer;
