import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../store/store';
import Menu from '../../components/ui/icons/Menu/Menu';
import MenuDropdown from '../../components/ui/header/MenuDropdown';
import { logout } from '../../store/features/user/userSlice';

interface MenuDropDownProps {
  onProfileClick: () => void;
  onSettingsClick: () => void;
  onPrivateMobileNumberClick: () => void;
}

function MenuDropDown({
  onProfileClick,
  onSettingsClick,
  onPrivateMobileNumberClick,
}: MenuDropDownProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const userRole = user.data?.accounts?.[0]?.right_level;
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleMenuClick();
    navigate('/login');
  };

  const items = [
    { name: t('profile'), action: onProfileClick },
    ...(userRole === 'admin' ? [{ name: t('settings'), action: onSettingsClick }] : []),
    { name: t('private_mobile_numbers'), action: onPrivateMobileNumberClick },
    { name: t('logout'), action: handleLogout },
  ];

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (menuRef.current && !menuRef.current.contains(target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className="menu-dropdown-icon" ref={menuRef}>
      <button
        type="button"
        aria-label="menu"
        className="header__menu-button"
        onClick={handleMenuClick}
      >
        <Menu />
      </button>
      <MenuDropdown
        dropdownItems={items}
        open={isOpen}
        onClose={handleMenuClick}
      />
    </div>
  );
}

export default MenuDropDown;
