import { combineReducers } from '@reduxjs/toolkit';

import userReducer from './features/user/userSlice';
import recordingsReducer from './features/recordings/recordingsSlice';
import todosReducer from './features/todos/todosSlice';
import appointmentReducer from './features/appointments/appointmentsSlice';
import partnerReducer from './features/partner/partnerSlice';

const rootReducer = combineReducers({
  user: userReducer,
  recordings: recordingsReducer,
  todos: todosReducer,
  appointment: appointmentReducer,
  partner: partnerReducer,
});

export default rootReducer;
