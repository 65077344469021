import React from 'react';

import './summary-list.css';

import List from '../../../ui/list/List';
import { customUuid } from '../../../../utils/constants';

interface SummaryProps {
  summaries: string[];
}

function SummaryList({ summaries }: SummaryProps) {
  return (
    <List className="summary__list">
      {summaries.map((summary) => (
        <li key={customUuid()} dangerouslySetInnerHTML={{ __html: summary }} />
      ))}
    </List>
  );
}

export default SummaryList;
