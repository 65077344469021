import React, { useState } from 'react';

import './todo-item.css';

import Checkbox from '../../ui/checkbox';
import ListItem from '../../ui/list-item/ListItem';
import { Todo } from '../../../store/features/todos/types';
import useMarkTodoAsDone from '../../../hooks/useMarkTodoAsDone';
import { firstLetterUpperCase } from '../../../utils/constants';

interface TodoItemProps {
  todo: Todo;
  onTodoClick?: () => void;
}

function TodoItem({
  todo,
  onTodoClick,
}: TodoItemProps) {
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const markTodo = useMarkTodoAsDone({ todo, markAsDone: !todo.completed });

  const handleCheckboxClick = async () => {
    try {
      setIsFormDisabled(true);
      await markTodo();
    } catch (error) {
      console.error('Failed to mark todo as done', error);
    } finally {
      setIsFormDisabled(false);
    }
  };

  return (
    <ListItem className="todo-item">
      <div className="todo-item__task">
        <Checkbox
          key={todo.id}
          done={todo.completed}
          text={firstLetterUpperCase(todo.sentence)}
          handleCheckboxClick={handleCheckboxClick}
          handleTextClick={onTodoClick && onTodoClick}
          disabled={isFormDisabled}
        />
      </div>
    </ListItem>
  );
}

TodoItem.defaultProps = {
  onTodoClick: () => {},
};

export default TodoItem;
