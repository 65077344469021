import React, { useState, useEffect, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import './profile.css';

import Modal from '../../ui/modal';
import Input from '../../ui/input';
import Close from '../../ui/icons/Close/Close';
import { UserData } from '../../../store/features/user/types';

interface ProfileProps {
  userData: UserData | null;
  errorMessages: string | null;
  onClose: () => void;
  onSave: (profileData: {
    username: string;
    firstName: string;
    lastName: string;
    personalEmail: string;
    mobileNo: string;
    emailAliases: string[];
  }) => void;
}

function Profile({
  errorMessages,
  userData,
  onClose,
  onSave,
}: ProfileProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation();
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>(t('save-changes'));
  const [form, setForm] = useState({
    username: userData?.username || '',
    firstName: userData?.first_name || '',
    lastName: userData?.last_name || '',
    personalEmail: userData?.email || '',
    mobileNo: userData?.mobile_number || '',
    emailAliases: userData?.email_aliases.join(', ') || '',
  });

  useEffect(() => {
    if (errorMessages) {
      setErrorMessage(errorMessages);
    }
  }, [errorMessages]);

  const handleChange = (fieldName: string, value: string) => {
    setForm((prevForm) => ({
      ...prevForm,
      [fieldName]: fieldName === 'emailAliases' ? value : value,
    }));

    setErrorMessage(null);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = (preparedFormData: {
    username: string;
    firstName: string;
    lastName: string;
    personalEmail: string;
    mobileNo: string;
    emailAliases: string[];
  }) => {
    if (
      [
        preparedFormData.username,
        preparedFormData.firstName,
        preparedFormData.lastName,
        preparedFormData.personalEmail,
      ].some((value) => value.trim() === '')
    ) {
      return t('profile-form-error');
    }
    if (!isValidEmail(preparedFormData.personalEmail)) {
      return t('profile-form-invalid-email');
    }

    return null;
  };

  const handleSave = async () => {
    const cleanedEmailAliases = form.emailAliases
      .split(',')
      .map((alias) => alias.trim())
      .filter((alias) => alias);

    const allEmailsValid = cleanedEmailAliases.every((alias) => alias.includes('@'));

    if (!allEmailsValid) {
      setErrorMessage(t('profile-form-invalid-email'));
      return;
    }

    const preparedFormData = {
      ...form,
      emailAliases: cleanedEmailAliases,
    };

    const validationResult = validateForm(preparedFormData);
    if (validationResult === null) {
      setIsFormDisabled(true);
      setButtonText(t('saving'));

      await onSave(preparedFormData);
      setButtonText(t('saved'));
      setIsFormDisabled(false);
    } else {
      setErrorMessage(validationResult);
      setIsFormDisabled(false);
      setButtonText(t('save-changes'));
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSave();
  };

  return (
    <Modal isOpen className="h-300">
      <div className="recording-detail__header">
        <button
          aria-label="close-modal"
          type="button"
          className="recording-detail__close-button"
          onClick={onClose}
        >
          <Close />
        </button>
        <h2 className="mt-0">{t('profile')}</h2>
      </div>
      <div className="profile-modal">
        <form className="profile-form" onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <div className="label">{t('username')}</div>
            <Input
              type="text"
              value={form.username}
              className={
                errorMessage && !form.username.trim() ? 'border-error' : ''
              }
              isRequired
              onChange={(e) => handleChange('username', e.target.value)}
              disabled={isFormDisabled}
            />
          </div>
          <div className="input-wrapper">
            <div className="label">{t('first-name')}</div>
            <Input
              type="text"
              value={form.firstName}
              className={
                errorMessage && !form.firstName.trim() ? 'border-error' : ''
              }
              isRequired
              onChange={(e) => handleChange('firstName', e.target.value)}
              disabled={isFormDisabled}
            />
          </div>
          <div className="input-wrapper">
            <div className="label">{t('last-name')}</div>
            <Input
              type="text"
              value={form.lastName}
              className={
                errorMessage && !form.lastName.trim() ? 'border-error' : ''
              }
              isRequired
              onChange={(e) => handleChange('lastName', e.target.value)}
              disabled={isFormDisabled}
            />
          </div>
          <div className="input-wrapper">
            <div className="label">{t('personal-email')}</div>
            <Input
              type="text"
              value={form.personalEmail}
              className={
                errorMessage && !form.personalEmail.trim() ? 'border-error' : ''
              }
              isRequired
              onChange={(e) => handleChange('personalEmail', e.target.value)}
              disabled={isFormDisabled}
            />
          </div>
          <div className="input-wrapper">
            <div className="label">{t('mobile-number')}</div>
            <Input
              type="text"
              value={form.mobileNo}
              isRequired={false}
              onChange={(e) => handleChange('mobileNo', e.target.value)}
              disabled={isFormDisabled}
            />
          </div>
          <div className="input-wrapper">
            <div className="label">{t('email-aliases')}</div>
            <Input
              type="text"
              value={form.emailAliases}
              isRequired={false}
              onChange={(e) => handleChange('emailAliases', e.target.value)}
              disabled={isFormDisabled}
            />
          </div>
          {errorMessage && <div className="form-error">{errorMessage}</div>}
          <button type="submit" className="button">
            {buttonText}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default Profile;
