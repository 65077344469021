/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import './recording-detail.css';
import RecordingPlayer from '../RecordingPlayer';

import Summary from './Summary';
import Todo from './ConversationTodo';
import Modal from '../../ui/modal/Modal';
import Button from '../../ui/button/Button';
import NoDataToShow from '../../ui/ui-feedback/NoDataToShow';
import AppointmentItem from '../../appointment/AppointmentItem/AppointmentItem';
import { TranscriptInfo } from '../../../store/features/recordings/models/types';
import { Appointment } from '../../../store/features/appointments/types';
import {
  SUMMARY_OPENED,
  APPOINTMENT_OPENED,
  TODO_OPENED,
  CallDirections,
} from '../../../utils/constants';
import { SummaryData } from '../../../store/features/recordings/types';
import { Todo as TodoType } from '../../../store/features/todos/types';
import Recording from '../../../store/features/recordings/models/Recording';

import RecordingModalHeader from '../RecordingModalHeader';
import BurgerMenu from '../../ui/icons/BurgerMenu/BurgerMenu';

interface RecordingDetailProps {
  activeTab: number;
  recording: Recording;
  summaryData?: SummaryData | null;
  appointmentData?: Appointment[] | null;
  transcriptData: TranscriptInfo[];
  startTime?: number;
  todoData: TodoType[] | null;
  onClickDelete: () => void;
  setActiveTab: (value: number | ((prevVar: number) => number)) => void;
  closeModal: () => void;
  onSwitchCallType?: () => void;
  onAppointmentClick?: (appointment: Appointment) => void;
  onTodoClick?: (todo: TodoType) => void;
}

function RecordingDetail({
  recording,
  activeTab,
  summaryData,
  appointmentData,
  transcriptData,
  startTime,
  todoData,
  closeModal,
  setActiveTab,
  onSwitchCallType,
  onAppointmentClick,
  onTodoClick,
  onClickDelete,
}: RecordingDetailProps) {
  const summaryRef = useRef<HTMLDivElement>(null);
  const todoRef = useRef<HTMLDivElement>(null);
  const appointmentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [isModalRendered, setIsModalRendered] = useState(false);
  const [isViewFullTranscript, setIsViewFullTranscript] = useState(false);

  const isCallIncoming = recording.info.call_direction === CallDirections.Incoming;

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof recording.urlInfo?.url !== 'undefined') {
      const timer = setTimeout(() => setIsModalRendered(true), 300);
      return () => clearTimeout(timer);
    }
    setIsModalRendered(false);
  }, [recording.urlInfo?.url]);

  useEffect(() => {
    if (!isModalRendered) return;

    if (activeTab === SUMMARY_OPENED) {
      scrollToSection(summaryRef);
    } else if (activeTab === TODO_OPENED) {
      scrollToSection(todoRef);
    } else if (activeTab === APPOINTMENT_OPENED) {
      scrollToSection(appointmentRef);
    }
  }, [activeTab]);

  const incomingOrOutgoingCall = recording.info.call_direction
    && recording.info.call_direction === 'incoming'
    ? t('incoming')
    : t('outgoing');

  const getDisplayName = (name: string) => {
    const search = 'call with';

    if (name.toLowerCase().includes(search)) {
      return name.replace(new RegExp(search, 'gi'), t('call_with'));
    }

    return name;
  };

  return (
    <Modal
      isOpen
      className={`${recording.info.private ? 'h-300' : ''}`}
      align="center"
    >
      <RecordingModalHeader
        recordingMeetingTime={recording.info.meeting_time}
        recordingName={getDisplayName(recording.info.name)}
        isCallIncoming={isCallIncoming}
        callDirection={incomingOrOutgoingCall}
        recordingDate={recording.info.date}
        onClickDelete={onClickDelete}
        closeModal={closeModal}
      />
      <div className="recording-detail__player-wrapper">
        <div className="recording-detail__transcript-button">
          <button
            type="button"
            className="recording-detail__menu"
            onClick={() => setIsViewFullTranscript(!isViewFullTranscript)}
          >
            <BurgerMenu />
          </button>
        </div>
        <RecordingPlayer
          url={recording.urlInfo?.url ?? null}
          fullTranscript={transcriptData}
          isMenuOpen={isViewFullTranscript}
          startTime={startTime}
          recording={recording}
        />
      </div>
      {recording.info.private ? (
        <div className="recording-detail__private-call">
          <span className="recording-detail__private-call-msg">
            {t('private_call_msg')}
          </span>
          <button
            type="button"
            className="recording-detail__private-call-switch-button"
            onClick={onSwitchCallType}
          >
            {t('private_to_business_msg')}
          </button>
        </div>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!isViewFullTranscript && (
            <>
              <div className="recording-detail__buttons">
                <Button
                  className={`recording-item__button ${
                    activeTab === SUMMARY_OPENED
                      ? 'recording-item__button-active-color'
                      : ''
                  }`}
                  handleClick={() => {
                    scrollToSection(summaryRef);
                    setActiveTab(SUMMARY_OPENED);
                  }}
                  type="button"
                  description={t('summary')}
                />
                <Button
                  className={`recording-item__button ${
                    activeTab === TODO_OPENED
                      ? 'recording-item__button-active-color'
                      : ''
                  }`}
                  handleClick={() => {
                    scrollToSection(todoRef);
                    setActiveTab(TODO_OPENED);
                  }}
                  type="button"
                  description={t('todos')}
                />
                <Button
                  className={`recording-item__button ${
                    activeTab === APPOINTMENT_OPENED
                      ? 'recording-item__button-active-color'
                      : ''
                  }`}
                  handleClick={() => {
                    scrollToSection(appointmentRef);
                    setActiveTab(APPOINTMENT_OPENED);
                  }}
                  type="button"
                  description={t('appointment')}
                />
              </div>
              <div className="recording-detail__tab-line" />
              <div className="recording-detail__content">
                <div
                  className="recording-detail__appointment-content"
                  ref={appointmentRef}
                >
                  {appointmentData && appointmentData.length > 0 ? (
                    appointmentData.map((appointment) => (
                      <AppointmentItem
                        key={appointment.id()}
                        appointment={appointment}
                        isUsedInModal
                        onClick={onAppointmentClick || (() => {})}
                      />
                    ))
                  ) : (
                    <NoDataToShow text={t('call_no_appointments')} />
                  )}
                </div>
                <div ref={summaryRef}>
                  {summaryData && <Summary summaryData={summaryData} />}
                </div>
                <div ref={todoRef}>
                  {todoData && (
                    <Todo todoData={todoData} onClick={onTodoClick} />
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
}

RecordingDetail.defaultProps = {
  summaryData: [],
  appointmentData: null,
  startTime: undefined,
  onSwitchCallType: () => {},
  onAppointmentClick: () => {},
  onTodoClick: () => {},
};

export default RecordingDetail;
