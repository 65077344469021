import React from 'react';
import { useTranslation } from 'react-i18next';

import './no-data-to-show.css';
import Info from '../../icons/Info/Info';

interface NoDataToShowProps {
  text?: string;
}

function NoDataToShow({ text }: NoDataToShowProps) {
  const { t } = useTranslation();
  const defaultText = t('no_data');

  return (
    <div className="no-data-to-show">
      <Info />
      <p>{text || defaultText}</p>
    </div>
  );
}

NoDataToShow.defaultProps = {
  text: '',
};

export default NoDataToShow;
