import React from 'react';

import './menu-item.css';

interface MenuItemProps {
  children: React.ReactNode;
  action: any;
}

function CustomMenuItem({ children, action }: MenuItemProps) {
  return (
    <div
      className="menu-dropdown__item"
      onClick={() => action && action()}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'Enter' && action) action();
      }}
    >
      {children}
    </div>
  );
}

export default CustomMenuItem;
