import React, { useState } from 'react';

import './your-assistant.css';

import Header from '../../ui/header/Header';
import NavBar from '../../ui/navbar/NavBar';
import TabPanel from '../../ui/tab-panel/TabPanel';

import Profile from '../../../containers/Profile';
import Settings from '../../../containers/Settings';
import PrivateMobileNumber from '../../../containers/PrivateMobileNumber';

interface TabData {
  label: string;
  icon: React.ReactNode;
  content: React.ReactNode;
}

interface YourAssistantProps {
  tabs: TabData[];
  searchTerm: string;
  handleSearchByTerm: (term: string) => void;
  partnerIcon?: string;
}

function YourAssistant({
  tabs,
  searchTerm,
  handleSearchByTerm,
  partnerIcon,
}: YourAssistantProps) {
  const [activeTab, setActiveTab] = useState(0);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isPrivateMobileNumberOpen, setIsPrivateMobileNumberOpen] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <main>
      <Header
        value={searchTerm}
        onChange={handleSearchByTerm}
        onSettingsClick={() => setIsSettingsOpen(true)}
        onProfileClick={() => setIsProfileOpen(true)}
        onPrivateMobileNumberClick={() => setIsPrivateMobileNumberOpen(true)}
        partnerIcon={partnerIcon}
      />
      <div className="container">
        {tabs.map((tab, index) => (
          <TabPanel key={tab.label} value={activeTab} index={index}>
            {tab.content}
          </TabPanel>
        ))}
      </div>
      {isSettingsOpen && (
        <Settings onClose={() => setIsSettingsOpen(false)} />
      )}
      {isProfileOpen && (
        <Profile onClose={() => setIsProfileOpen(false)} />
      )}
      {isPrivateMobileNumberOpen && (
        <PrivateMobileNumber onClose={() => setIsPrivateMobileNumberOpen(false)} />
      )}
      <NavBar activeTab={activeTab} tabs={tabs} handleTabChange={handleTabChange} />
    </main>
  );
}

YourAssistant.defaultProps = {
  partnerIcon: null,
};

export default YourAssistant;
