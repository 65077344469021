import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './index.css';
import './i18nConfig';
import PreloadWrapper from './containers/Preload';
import YourAssistant from './containers/YourAssistant';
import { RootState, store } from './store/store';
import Login from './containers/Login';
import { authorized } from './store/features/user/userSlice';
import useTheme from './store/features/partner/hooks/useTheme';
import usePartnerIcon from './store/features/partner/hooks/usePartnerIcon';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const loader = document.getElementById('initial-loader');
loader?.remove();

interface ProtectedRouteProps {
  children: React.ReactElement;
}

interface RedirectIfAuthorizedRouteProps {
  children: React.ReactElement;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  if (!authorized()) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function RedirectIfAuthorized({ children }: RedirectIfAuthorizedRouteProps) {
  if (authorized()) {
    return <Navigate to="/" replace />;
  }

  return children;
}

function App() {
  const { i18n, t } = useTranslation();
  const partner = useSelector((state: RootState) => state.partner.data);
  useTheme(partner?.theme);
  usePartnerIcon(partner?.icon);

  useEffect(() => {
    document.title = t('app_name');
  }, [i18n.language]);

  return (
    <Routes>
      <Route
        path="/login"
        element={(
          <RedirectIfAuthorized>
            <Login />
          </RedirectIfAuthorized>
        )}
      />
      <Route
        path="/"
        element={(
          <ProtectedRoute>
            <YourAssistant />
          </ProtectedRoute>
        )}
      />
      <Route path="/logout" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <PreloadWrapper>
          <App />
        </PreloadWrapper>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
