import React from 'react';
import { useTranslation } from 'react-i18next';

import './appointment-modal-item.css';

import ListItem from '../../../ui/list-item/ListItem';
import { Appointment } from '../../../../store/features/appointments/types';
import CloseButton from '../../../ui/button/CloseButton';
import Location from '../../../ui/icons/Location/Location';
import { firstLetterUpperCase } from '../../../../utils/constants';

interface AppointmentModalItemProps {
  appointment: Appointment;
  onClose: () => void;
}

function AppointmentModalItem({ appointment, onClose }: AppointmentModalItemProps) {
  const { t } = useTranslation();
  const [day, month] = appointment.dayAndMonthAbbreviation(t).split(' ');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <ListItem className="appointment-modal-item">
        <span className="appointment-modal-item__icon">
          <div className="appointment-modal-item__date-container">
            <span className="appointment-modal-item__date-day">{day}</span>
            <span className="appointment-modal-item__date-month">{month}</span>
          </div>
        </span>
        <div className="appointment-modal-item__info">
          {appointment.name() && <div>{appointment.name()}</div>}
          <div>
            <span className="appointment-modal-item__general-info">
              {t('scheduled_at')}
              :
            </span>
            <span className="appointment-modal-item__highlighted-info">
              {appointment.formattedFollowUpDate()}
            </span>
          </div>
          <p className="appointment-modal-item__sentence">
            {firstLetterUpperCase(appointment.sentence())}
          </p>
          {
            appointment.location() !== ''
            && appointment.location() !== null
            && appointment.location() !== 'null'
            && (
              <span className="appointment-modal-item__location">
                {t('location')}
                :
                {' '}
                <Location />
                {appointment.location()}
              </span>
            )
          }
        </div>
      </ListItem>
      <CloseButton onClick={onClose} />
    </div>
  );
}

export default AppointmentModalItem;
