import React from 'react';

function Chat() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0C8.07525 0 0 8.07525 0 18C0 21.4605 0.96975 24.7838 2.808 27.648L0.081 34.4565C-0.0855 34.875 0.01125 35.352 0.33075 35.6715C0.5445 35.8853 0.8325 36 1.125 36C1.26675 36 1.40625 35.973 1.5435 35.919L8.352 33.192C11.2162 35.0303 14.5395 36 18 36C27.9247 36 36 27.9247 36 18C36 8.07525 27.9247 0 18 0ZM19.125 22.5H10.125C9.504 22.5 9 21.996 9 21.375C9 20.754 9.504 20.25 10.125 20.25H19.125C19.746 20.25 20.25 20.754 20.25 21.375C20.25 21.996 19.746 22.5 19.125 22.5ZM25.875 15.75H10.125C9.504 15.75 9 15.246 9 14.625C9 14.004 9.504 13.5 10.125 13.5H25.875C26.496 13.5 27 14.004 27 14.625C27 15.246 26.496 15.75 25.875 15.75Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Chat;
