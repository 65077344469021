import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppointmentModal from '../../components/appointment/AppointmentModal';
import LoadingComponent from '../../components/ui/ui-feedback/LoadingComponent';

import { AppDispatch, RootState } from '../../store/store';
import { Appointment, AppointmentData } from '../../store/features/appointments/types';
import { loadRecordingTranscript, loadRecordingUrl } from '../../store/features/recordings/actions';

import { Status } from '../../utils/constants';

interface AppointmentModalContainerProps {
  appointment: Appointment;
  onClose: () => void;
}

function AppointmentModalContainer({ appointment, onClose }: AppointmentModalContainerProps) {
  const [urlLoaded, setUrlLoaded] = useState(false);
  const [transcriptLoaded, setTranscriptionLoaded] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.data);
  const accountId = useMemo(() => user?.accounts[0]?.id ?? '', [user?.accounts]);
  const {
    status,
    transcriptStatus,
    urlStatus,
  } = useSelector((state: RootState) => state.recordings);

  const recording = useSelector((state: RootState) => {
    if (appointment.sessionId() in state.recordings.data) {
      return state.recordings.data[appointment.sessionId()];
    }

    return null;
  });

  useEffect(() => {
    if (!recording?.info.id) return;

    if (!transcriptLoaded && !recording.transcriptDetails) {
      dispatch(loadRecordingTranscript({ sessionId: recording.sessionId }));
      setTranscriptionLoaded(true);
    }

    if (!urlLoaded && !recording.urlInfo) {
      dispatch(loadRecordingUrl({ sessionId: recording.sessionId }));
      setUrlLoaded(true);
    }
  }, [
    dispatch,
    accountId,
    recording,
    transcriptLoaded,
    urlLoaded,
    appointment,
  ]);

  const isLoading = useMemo(
    () => [recording, status, transcriptStatus, urlStatus].some(
      (s) => s === Status.Loading,
    ),
    [recording, status, transcriptStatus, urlStatus],
  );

  if (isLoading || !recording) {
    return <LoadingComponent />;
  }

  const appointmentData = recording.appointments.find(
    (a: AppointmentData) => a.id === appointment.id(),
  );

  if (!appointmentData) {
    return <LoadingComponent />;
  }

  return (
    <AppointmentModal
      recording={recording}
      appointment={new Appointment(appointmentData)}
      onClose={onClose}
    />
  );
}

export default AppointmentModalContainer;
