import React, { useState } from 'react';

import './select.css';

interface SelectProps {
  value: string;
  options: { label: string; value: string }[];
  onChange: (data: { label: string; value: string }) => void;
}

function Select({ value, options, onChange }: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = options.find(
      (option) => option.value === event.target.value,
    );

    if (selectedOption) {
      onChange(selectedOption);
    }
    setIsOpen(false);
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="select-container">
      <select
        value={value}
        onChange={handleSelectChange}
        className={`select-native ${isOpen ? 'open' : ''}`}
        onMouseDown={toggleOpen}
        onBlur={() => setIsOpen(false)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
