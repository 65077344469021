import React from 'react';

interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby="tabpanel"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.defaultProps = {
  children: null,
};

export default TabPanel;
