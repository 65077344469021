import axiosInstance from '../../../utils/axiosInstance';
import { PartnerData } from './data';

const getPartner = async (partnerId: string): Promise<PartnerData> => {
  const url = `partner/${partnerId}`;
  const response = await axiosInstance.get(url);

  return response.data.partner;
};

export default getPartner;
