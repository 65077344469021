import React from 'react';

import './link-button.css';

type ButtonType = 'submit' | 'reset' | 'button';

interface LinkButtonProps {
  description: string;
  type: ButtonType;
  handleClick: () => void;
}

function LinkButton({ description, type, handleClick }: LinkButtonProps) {
  return (
    // eslint-disable-next-line react/button-has-type
    <button className="link-button" type={type} onClick={handleClick}>
      {description}
    </button>
  );
}

export default LinkButton;
