import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../store/store';
import { Todo, TodoSimpleObject } from '../../store/features/todos/types';
import { loadRecordingTranscript, loadRecordingUrl } from '../../store/features/recordings/actions';
import { Status } from '../../utils/constants';

import TodoModal from '../../components/todo/TodoModal';
import LoadingComponent from '../../components/ui/ui-feedback/LoadingComponent';

interface TodoModalContainerProps {
  todo: Todo;
  onClose: () => void;
}

function TodoModalContainer({ todo, onClose }: TodoModalContainerProps) {
  const [urlLoaded, setUrlLoaded] = useState(false);
  const [transcriptLoaded, setTranscriptionLoaded] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.data);
  const accountId = useMemo(() => user?.accounts[0]?.id ?? '', [user?.accounts]);
  const {
    status,
    transcriptStatus,
    urlStatus,
  } = useSelector((state: RootState) => state.recordings);

  const recording = useSelector((state: RootState) => {
    if (todo.session_id in state.recordings.data) {
      return state.recordings.data[todo.session_id];
    }

    return null;
  });

  useEffect(() => {
    if (!recording?.info.id) return;

    if (!transcriptLoaded && !recording.transcriptDetails) {
      dispatch(loadRecordingTranscript({ sessionId: recording.sessionId }));
      setTranscriptionLoaded(true);
    }

    if (!urlLoaded && !recording.urlInfo) {
      dispatch(loadRecordingUrl({ sessionId: recording.sessionId }));
      setUrlLoaded(true);
    }
  }, [
    dispatch,
    accountId,
    recording,
    transcriptLoaded,
    urlLoaded,
    todo,
  ]);

  const isLoading = useMemo(
    () => [recording, status, transcriptStatus, urlStatus].some(
      (s) => s === Status.Loading,
    ),
    [recording, status, transcriptStatus, urlStatus],
  );

  if (isLoading || !recording) {
    return <LoadingComponent />;
  }

  const recordingTodo = recording.todos.find((t: TodoSimpleObject) => t.id === todo.id);

  if (!recordingTodo) {
    return <LoadingComponent />;
  }

  return (
    <TodoModal
      recording={recording}
      todo={new Todo(recordingTodo)}
      onClose={onClose}
    />
  );
}

export default TodoModalContainer;
