import React from 'react';

import './menu-dropdown.css';

import MenuItem from '../../menu-item';

interface MenuDropdownProps {
  open: boolean;
  dropdownItems: {
    name: string;
    action?: () => void;
  }[];
  onClose: () => void;
}

function MenuDropdown({
  open,
  onClose,
  dropdownItems,
}: MenuDropdownProps) {
  if (!open) return null;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className="menu-dropdown"
      tabIndex={0}
      onClick={onClose}
      role="menu"
    >
      {dropdownItems.map((item) => (
        <MenuItem
          key={item.name}
          action={() => {
            if (item.action) item.action();
            onClose();
          }}
        >
          {item.name}
        </MenuItem>
      ))}
    </div>
  );
}

export default MenuDropdown;
