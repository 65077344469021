import React from 'react';

function LoaderDashed() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="6.5" width="13" height="13" transform="rotate(30 6.5 0)" fill="url(#pattern0)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2886_2483" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_2886_2483"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEvklEQVR4nO2cWYwVRRSGP5jrDIMjKNFINHGZB2KCvrghblET4xozLBoTFxYhMUEFDJE3woPECEpEcXl14UWjgobA4PKgwSVGEB58cwOEoDJjXBhHBU2FY3LT6aruK9Xd1dXnSzqZ3Onbp+rv7rqnTp1ToCiKoiiKoiiKouSmC7gIuA94HNgI7Aa+AoaAUTmG5DPzvzfl3PnyXXMNJYUzgYeATcDPwD/HeQzLDXoQOIOGMw64CxgE/vYgru0w194itnpoECcCi4F9BYprOw4CK4EJREwLWAr8VIHAyeNHudmmTVFxMfBZAAInjy+Ay4mAbmAdcLRDAX6VcXUVcC8wDegHTpFrdsvf/fK/OXKu+c5vHdoybVsr16wl/R0+xd8Da+QJO55X+gTgCuAJYH8H9j8FzqFmXCsuVp4ObgNuKMj3Nde8CXg3Z1uMb341NWEm8EeOTm0BLimxXZeJK5nVrhFggMCZl8Mn3gPMqLCNs4G9GW00fZhLwE9ylsgvAX1VNxQ4CdiQQ+yBEMfkEUejD8vTHhoLMto9EtKY3Z/xwzckXkCoXJWj/ZV7I90ZLpxx2c4nfC7IcAU/EbexMtZlPAl1ELldbNeT/SQVcaVjxnc48OHCNYzYxuwj4iKWSkviBLa7H6xrlIOFjn7tLjsQtTTDhas7Gxz9M4sJpdDnCHV+F4if7MPPtk1qfgDGUwLLHHe7yhmfb+5w9HMJJSw/7XfELmLjHUtf9xW9LHa34y5PJz6mOfp7Z5GGBx2hzlh539LnzUWmBNiCRiaeHCs3W/r8FzC5CIOLHdPsmBNWWsABS98XFWHwLYux1cTPWkvfX/dtqMsRB4hiFTnH1Dyt74eAsXhOF7CtVlca1SoxSmlbXb/Qp6H5DfKdO/W4vMZ1VluMPEpzeMyigfncG5ssRu6hOcy1aPCGTyO7LEbMzKkpTLdosNOnkW8tRs6mOZxr0eBrn0YOWYxMojmc6shM9caoxUhtEwP/Bz0WDUxWljeGLYuvTWO4aB3Wpxh4muaxvmgdeoEXgN/leF4+axq9ZekwRo6mM0Z1UBRFUcZJjLpRlahlYmaCD7RVvb4nuR6KZ2akOOwbfS/nKMfqqJNC/6I+pX+eSRF6ewF2Gk+flBn8J/JIUbkNyrEYtJnvP6Wbjii+OA9YXrNanFoxEXilLdfQrDCp2AVwoyXH0FQCBEMMy1uPWJasgtixZgrwsrxugzVfGJiXIvQOAuD0lNzpt2uel7eyrS9fArcQANdbVojr/gNym2w7EUyIYVGKyKORjNfB7WW3PSG0qf1QCuBkKaDZKdVbXYEvrs6SkmPjI99adYNiZVXi7TMxm2uIjLOAZyUnwszAqmBb7En1yxJ5fB+WVVedYG/sRU87Atlw5PaE338kpjr2lmXTERMdc2EmPVNzTn/NxiVbZQ/qJTnqcsxuDK/J7jPR0BKvJCm02WnRJrDZxesbOW9rhl+e3I3gqIjZSHqBD0QI8zTd75imD6TclFcds7Q1Keeb1frG0iO1eVmzxhWWukZbkmXa22KCW0oGz6UI97Hj/EvlRrSfbwJDSgbGx/68TbQ/gYczvnOdjOkfRb7jgndOA16UyU3luykqiqIoiqIoiqIQI/8C/nkyGYOgQ9wAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
}

export default LoaderDashed;
