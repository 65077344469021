import React from 'react';

function Close() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48597 0 0 4.48597 0 10C0 15.514 4.48597 20 10 20C15.514 20 20 15.514 20 10C20 4.48597 15.514 0 10 0ZM14.1784 12.8928C14.5335 13.2477 14.5335 13.8235 14.1784 14.1784C14.0008 14.3559 13.7682 14.4447 13.5356 14.4447C13.303 14.4447 13.0704 14.3559 12.8928 14.1784L10 11.2855L7.10716 14.1784C6.92982 14.3559 6.697 14.4447 6.4644 14.4447C6.2318 14.4447 5.99898 14.3559 5.82164 14.1784C5.46653 13.8235 5.46653 13.2477 5.82164 12.8928L8.71449 10L5.82164 7.10716C5.46653 6.75226 5.46653 6.17654 5.82164 5.82164C6.17631 5.46653 6.75249 5.46653 7.10716 5.82164L10 8.71449L12.8928 5.82164C13.248 5.46653 13.8232 5.46653 14.1784 5.82164C14.5335 6.17654 14.5335 6.75226 14.1784 7.10716L11.2855 10L14.1784 12.8928Z"
        fill="#999999"
      />
    </svg>
  );
}

export default Close;
