import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import ConversationSkeleton from '../ConversationSkeleton';
import RecordingItem from '../RecordingItem';
import List from '../../ui/list/List';
import NoDataToShow from '../../ui/ui-feedback/NoDataToShow';
import LoadingComponent from '../../ui/ui-feedback/LoadingComponent';
import { Status } from '../../../utils/constants';
import Recording, { RecordingConversations } from '../../../store/features/recordings/models/Recording';
import RecordingDetail from '../../../containers/RecordingDetail';
import { Todo } from '../../../store/features/todos/types';
import { Appointment } from '../../../store/features/appointments/types';
import TodoModal from '../../../containers/TodoModal';
import AppointmentModal from '../../../containers/AppointmentModal';

interface ConversationProps {
  conversations: RecordingConversations;
  recordingStatus: string;
  recordingFromURI: Recording | null;
}

function Conversation({
  conversations,
  recordingStatus,
  recordingFromURI,
}: ConversationProps) {
  const isEmpty = Object.keys(conversations).length === 0;

  const isLoading = isEmpty && recordingStatus === Status.Loading;

  const isRefreshing = !isEmpty && recordingStatus === Status.Loading;

  const isFailed = !isLoading && !isRefreshing && (recordingStatus === Status.Failed || isEmpty);

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordingPreview, setRecordingPreview] = useState<Recording | null>(null);
  const [startTime, setStartTime] = useState<number | undefined>();
  const [todoPreview, setTodoPreview] = useState<Todo | null>(null);
  const [appointmentPreview, setAppointmentPreview] = useState<Appointment | null>(null);

  const handlePreviewRecording = useCallback((rec: Recording) => {
    setRecordingPreview(rec);
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    if (recordingFromURI && !isLoading && !isRefreshing && !isFailed) {
      handlePreviewRecording(conversations[recordingFromURI.sessionId]);
    } else if (!isLoading && !isRefreshing && !isFailed) {
      navigate('/');
    }
  }, [recordingFromURI]);

  if (isLoading) {
    return <ConversationSkeleton count={6} />;
  }

  if (isFailed) {
    return <NoDataToShow />;
  }

  const handleTodoClick = (todo: Todo) => {
    const foundTodo = conversations[todo.session_id]?.todos.find((item) => item.id === todo.id);

    setRecordingPreview(null);

    if (foundTodo) {
      setTodoPreview(new Todo(foundTodo));
    }
  };

  const handleAppointmentClick = (appointment: Appointment) => {
    const foundAppointment = conversations[appointment.sessionId()]?.appointments.find(
      (item) => item.id === appointment.id(),
    );

    setRecordingPreview(null);

    if (foundAppointment) {
      setAppointmentPreview(new Appointment(foundAppointment));
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);

    if (recordingFromURI) {
      navigate('/');
    }
  };

  return (
    <>
      {isRefreshing && <LoadingComponent />}
      <List>
        {Object.keys(conversations).map((session) => {
          const recording = conversations[session];

          return (
            <RecordingItem
              conversation={recording}
              key={session}
              onPreviewRecording={handlePreviewRecording}
              onTodoClick={handleTodoClick}
              onAppointmentClick={handleAppointmentClick}
            />
          );
        })}
      </List>
      {isModalOpen && recordingPreview && (
        <RecordingDetail
          recording={conversations[recordingPreview.sessionId]}
          closeModal={() => closeModal()}
          startTime={startTime}
          onTodoClick={handleTodoClick}
          onAppointmentClick={handleAppointmentClick}
        />
      )}

      {todoPreview && (
        <TodoModal
          todo={todoPreview}
          onClose={() => setTodoPreview(null)}
        />
      )}

      {appointmentPreview && (
        <AppointmentModal
          appointment={appointmentPreview}
          onClose={() => setAppointmentPreview(null)}
        />
      )}
    </>
  );
}

export default Conversation;
