import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppDispatch, RootState } from '../../store/store';
import ConversationComponent from '../../components/conversation/Conversation';
import { UserData } from '../../store/features/user/types';
import {
  loadFullRecordingDetails,
} from '../../store/features/recordings/actions';
import { Status } from '../../utils/constants';
import Recording from '../../store/features/recordings/models/Recording';

interface ConversationProps {
  user: UserData;
  searchTerm: string;
}

function Conversation({ user, searchTerm }: ConversationProps) {
  const dispatch = useDispatch<AppDispatch>();

  const {
    data: recordingsData,
    status: recordingStatus,
  } = useSelector(
    (state: RootState) => state.recordings,
  );

  const { status: todoStatus } = useSelector(
    (state: RootState) => state.todos,
  );

  const { status: appointmentStatus } = useSelector(
    (state: RootState) => state.appointment,
  );

  const [hasFetchedRecording, setHasFetchedRecording] = useState(false);
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const sessionId = urlSearchParams.get('session_id') ?? undefined;

  const userId = user.id;
  const accountId = user.accounts[0]?.id;

  useEffect(() => {
    dispatch(loadFullRecordingDetails({
      accountId,
      filter: searchTerm,
      userId,
    }));
  }, [userId, dispatch, searchTerm, accountId]);

  useEffect(() => {
    if (
      sessionId
      && recordingStatus === Status.Succeeded
      && [todoStatus, appointmentStatus].every((status) => status === Status.Succeeded)
      && !recordingsData[sessionId]
      && !hasFetchedRecording
    ) {
      dispatch(loadFullRecordingDetails({
        accountId,
        filter: searchTerm,
        userId,
        sessionId,
      }));
      setHasFetchedRecording(true);
    }
  }, [
    recordingStatus,
    todoStatus,
    appointmentStatus,
    sessionId,
    recordingsData,
    accountId,
    userId,
    searchTerm,
    hasFetchedRecording,
    dispatch,
  ]);

  let recordingFromURI: Recording | null = null;

  if (
    sessionId
    && [recordingStatus, todoStatus, appointmentStatus].every(
      (status) => status === Status.Succeeded,
    )
    && Object.keys(recordingsData).length > 0
    && recordingsData[sessionId]
  ) {
    recordingFromURI = recordingsData[sessionId];
  }

  return (
    <ConversationComponent
      conversations={recordingsData}
      recordingStatus={recordingStatus}
      recordingFromURI={recordingFromURI}
    />
  );
}

export default Conversation;
