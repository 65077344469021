interface ThemeObject {
  [key: string]: string;
}

const useTheme = (theme?: string) => {
  if (!theme) return;

  const themeStringToObject = (themeString: string): ThemeObject => {
    const themeObject: ThemeObject = {};
    const declarations = themeString
      .split(';')
      .map((declaration) => declaration.trim())
      .filter(Boolean);

    declarations.forEach((declaration) => {
      const [key, value] = declaration.split(': ');
      if (key && value) {
        themeObject[key.trim()] = value.trim();
      }
    });

    return themeObject;
  };

  const root = document.documentElement;
  const themeObject = themeStringToObject(theme);

  const applyTheme = (themeObj: ThemeObject) => {
    Object.entries(themeObj).forEach(([key, value]) => {
      root.style.setProperty(key, value);
    });
  };

  applyTheme(themeObject);
};

export default useTheme;
