import React from 'react';

import RecordingItemCard from '../RecordingItemCard';
import Recording from '../../../store/features/recordings/models/Recording';
import { Todo } from '../../../store/features/todos/types';
import { Appointment } from '../../../store/features/appointments/types';

interface RecordingItemProps {
  conversation: Recording;
  onPreviewRecording: (recording: Recording) => void;
  onTodoClick: (todo: Todo) => void;
  onAppointmentClick: (appointment: Appointment) => void;
}

function RecordingItem({
  conversation,
  onPreviewRecording,
  onTodoClick,
  onAppointmentClick,
}: RecordingItemProps) {
  return (
    <RecordingItemCard
      recording={conversation}
      onPreviewRecording={onPreviewRecording}
      onTodoClick={onTodoClick}
      onAppointmentClick={onAppointmentClick}
    />
  );
}

export default RecordingItem;
