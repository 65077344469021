import { TodoSimpleObject } from './types';
import axiosInstance from '../../../utils/axiosInstance';

export const fetchTodos = async (
  account: string,
  filter?: string,
  userId?: number,
  sessionId?: string,
): Promise<TodoSimpleObject[]> => {
  const searchParams = new URLSearchParams();
  if (filter) {
    searchParams.append('search', filter);
  }

  if (userId) {
    searchParams.append('global_user_ids[]', String(userId));
  }

  if (sessionId) {
    searchParams.append('session_id', sessionId);
  }

  const query = searchParams.toString() ? `?${searchParams.toString()}` : '';
  const url = `accounts/${account}/todos${query}`;
  const response = await axiosInstance.get<TodoSimpleObject[]>(url);

  return response.data;
};

export const markTodoDone = async (accountId: string, todoId: string) => {
  const url = `accounts/${accountId}/todos/${todoId}/done`;
  const response = await axiosInstance.post(url);

  return response.data;
};

export const markTodoUndone = async (accountId: string, todoId: string) => {
  const url = `accounts/${accountId}/todos/${todoId}/undone`;
  const response = await axiosInstance.post(url);

  return response.data;
};
