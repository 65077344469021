import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import './todo.css';
import TodoSkeleton from '../TodoSkeleton';
import Select from '../../ui/select';
import List from '../../ui/list/List';
import NoDataToShow from '../../ui/ui-feedback/NoDataToShow';
import LoadingComponent from '../../ui/ui-feedback/LoadingComponent';
import { Status } from '../../../utils/constants';
import TodoItem from '../../../containers/TodoItem';
import { Todo as TodoType } from '../../../store/features/todos/types';
import { RootState } from '../../../store/store';
import TodoModal from '../../../containers/TodoModal';

interface TodoProps {
  todos: Record<
  string,
  Record<string, { meetingName: string; todos: TodoType[] }>
  > | null;
  status: string;
  selectedGroupByValue: string;
  groupByOptions: { label: string; value: string }[];
  selectGroupByValue: (data: { label: string; value: string }) => void;
}

function Todo({
  todos: todoData,
  status,
  selectedGroupByValue,
  groupByOptions,
  selectGroupByValue,
}: TodoProps) {
  const [selectedTodo, setSelectedTodo] = useState<TodoType | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const hasData = todoData && Object.keys(todoData).length !== 0;
  const isLoading = !hasData && status === Status.Loading;
  const isRefreshing = hasData && status === Status.Loading;
  const isFailed = !isLoading && !isRefreshing && (status === Status.Failed || !hasData);

  const recording = useSelector((state: RootState) => {
    if (selectedTodo && selectedTodo.session_id in state.recordings.data) {
      return state.recordings.data[selectedTodo.session_id];
    }

    return null;
  });

  const handleTodoClick = (todo: TodoType) => {
    setSelectedTodo(todo);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedTodo(null);
  };

  if (isLoading) {
    return <TodoSkeleton count={7} />;
  }

  if (isFailed) {
    return <NoDataToShow />;
  }

  return (
    <>
      {isRefreshing && <LoadingComponent />}
      {isModalOpen && selectedTodo && recording && (
        <TodoModal
          onClose={handleModalClose}
          todo={selectedTodo}
        />
      )}
      <div className="todo-select-wrapper">
        <div className="todo-select-wrapper__title">
          {t('group_by')}
          :
        </div>
        <div className="todo-select-wrapper__select">
          <Select
            value={selectedGroupByValue}
            options={groupByOptions}
            onChange={selectGroupByValue}
          />
        </div>
      </div>
      <List>
        {todoData
        && Object.entries(todoData).map(([groupKey, meetings]) => (
          <div key={groupKey} className="todo-card">
            <div className="todo-header">
              {groupKey}
            </div>
            {Object.entries(meetings).map(([meetingId, { meetingName, todos }]) => (
              <div key={meetingId} className="meeting-section">
                <div className="meeting-date">
                  {todos[0].formatDateTime()}
                </div>
                <div className="meeting-name">{meetingName}</div>
                {todos.map((todo) => (
                  <div key={todo.id} className="meeting-todos">
                    <TodoItem
                      todo={todo}
                      onTodoClick={() => handleTodoClick(todo)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </List>
    </>
  );
}

export default Todo;
