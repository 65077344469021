import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AppointmentModalItem from './AppointmentModalItem';
import Modal from '../../ui/modal';
import CloseButton from '../../ui/button/CloseButton';
import RecordingPlayer from '../../conversation/RecordingPlayer';
import RecordingDetailsHeader from '../../conversation/RecordingDetailsHeader';

import Recording from '../../../store/features/recordings/models/Recording';
import { Appointment } from '../../../store/features/appointments/types';
import { TranscriptInfo } from '../../../store/features/recordings/models/types';

import { CallDirections } from '../../../utils/constants';

import './appointment-modal.css';

interface TodoModalProps {
  appointment: Appointment;
  recording: Recording;
  onClose: () => void;
}

function AppointmentModal({
  appointment,
  recording,
  onClose,
}: TodoModalProps) {
  const { t } = useTranslation();

  const isCallIncoming = recording.info.call_direction === CallDirections.Incoming;

  const getDisplayName = (name: string) => {
    const search = 'call with';

    if (name.toLowerCase().includes(search)) {
      return name.replace(new RegExp(search, 'gi'), t('call_with'));
    }

    return name;
  };

  const incomingOrOutgoingCall = recording.info.call_direction
  && recording.info.call_direction === 'incoming'
    ? t('incoming')
    : t('outgoing');

  const partialTranscript = useMemo(() => recording.transcriptDetails?.transcript.filter(
    (item: TranscriptInfo) => item.start >= appointment.start() && item.end <= appointment.end(),
  ) ?? [], [recording.transcriptDetails, appointment.start, appointment.end]);

  const callUrl = useMemo(() => recording.urlInfo?.url, [recording.urlInfo]);

  const callAppointment = useMemo(() => appointment, [appointment]);

  return (
    <Modal
      isOpen
      align="center"
    >
      <div className="appointment-wrapper">
        <AppointmentModalItem appointment={callAppointment} onClose={onClose} />
      </div>
      <RecordingDetailsHeader
        recordingMeetingTime={recording.info.meeting_time}
        recordingName={getDisplayName(recording.info.name)}
        isCallIncoming={isCallIncoming}
        callDirection={incomingOrOutgoingCall}
        recordingDate={recording.info.date}
      />
      <div>
        <RecordingPlayer
          fullTranscript={partialTranscript}
          isMenuOpen
          url={callUrl ?? null}
          startTime={callAppointment.start()}
          endTime={callAppointment.end()}
          recording={recording}
        />
      </div>
    </Modal>
  );
}

export default AppointmentModal;
