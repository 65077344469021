import React from 'react';

import './checkbox.css';
import CheckboxIcon from '../icons/Checkbox/Checkbox';

interface CheckboxProps {
  done: boolean;
  text: string;
  handleCheckboxClick: () => void;
  handleTextClick?: () => void;
  disabled?: boolean;
}

function Checkbox({
  done,
  text,
  disabled,
  handleCheckboxClick,
  handleTextClick,
}: CheckboxProps) {
  const onClick = () => {
    if (!disabled) {
      handleCheckboxClick();
    }
  };

  const onTextClick = () => {
    if (!disabled && handleTextClick) {
      handleTextClick();
    }
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if ((event.key === 'Enter' || event.key === ' ') && !disabled) {
      onTextClick();
    }
  };

  return (
    <div className="checkbox-container">
      <CheckboxIcon width="20" height="20" done={done} onClick={onClick} />
      <span
        onClick={onTextClick}
        onKeyDown={onKeyDown}
        tabIndex={disabled ? -1 : 0}
        style={{ textDecoration: done ? 'underline' : 'none', cursor: 'pointer' }}
        role="button"
        aria-disabled={disabled ? 'true' : 'false'}
      >
        {text}
      </span>
    </div>
  );
}

Checkbox.defaultProps = {
  handleTextClick: () => {},
  disabled: false,
};

export default Checkbox;
