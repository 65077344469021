import React from 'react';

import IncomingCall from '../../ui/icons/IncomingCall/IncomingCall';
import OutgoingCall from '../../ui/icons/OutgoingCall/OutgoingCall';

import { firstLetterUpperCase } from '../../../utils/constants';

import './recording-details-header.css';

interface RecordingDetailsHeaderProps {
  recordingName: string;
  recordingDate: string;
  recordingMeetingTime: number;
  callDirection: string;
  isCallIncoming: boolean;
}

function RecordingDetailsHeader({
  recordingName,
  recordingDate,
  recordingMeetingTime,
  callDirection,
  isCallIncoming,
}: RecordingDetailsHeaderProps) {
  return (
    <div className="recording-details-header">
      <button className="recording-details-header__avatar" type="button">
        {isCallIncoming
          ? <IncomingCall width="35px" height="35px" color="#00C26B" />
          : <OutgoingCall width="35px" height="35px" color="#00C26B" />}
      </button>
      <div className="recording-details-header__info-wrapper">
        <div className="recording-details-header__title">
          {recordingName}
        </div>
        <div className="recording-details-header__info-date">
          {`${firstLetterUpperCase(callDirection)} ${recordingDate}`}
        </div>
      </div>
    </div>
  );
}

export default RecordingDetailsHeader;
