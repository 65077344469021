import { AppointmentData } from './types';
import axiosInstance from '../../../utils/axiosInstance';

const fetchAppointments = async (
  account: string,
  filter?: string,
  userId?: number,
  sessionId?: string,
): Promise<AppointmentData[]> => {
  const searchParams = new URLSearchParams();

  if (filter) {
    searchParams.append('search', filter);
  }

  if (userId) {
    searchParams.append('global_user_ids[]', String(userId));
  }

  if (sessionId) {
    searchParams.append('session_id', sessionId);
  }

  const query = searchParams.toString() ? `?${searchParams.toString()}` : '';
  const url = `accounts/${account}/appointments${query}`;
  const response = await axiosInstance.get<AppointmentData[]>(url);

  return response.data;
};

export default fetchAppointments;
