import React from 'react';

interface MagnifyingGlassProps {
  className?: string;
}

function MagnifyingGlass({ className }: MagnifyingGlassProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5247 0C2.93038 0 0 2.93038 0 6.5247C0 10.119 2.93038 13.0494 6.5247 13.0494C8.03467 13.0494 9.42658 12.5317 10.5345 11.6654L14.6349 15.7658C14.9472 16.0781 15.4535 16.0781 15.7658 15.7658C16.0781 15.4535 16.0781 14.9472 15.7658 14.6349L11.6654 10.5345C12.5321 9.42692 13.051 8.0347 13.051 6.52473C13.051 2.93041 10.119 0 6.5247 0ZM6.5247 1.59956C9.25455 1.59956 11.4514 3.79485 11.4514 6.52473C11.4514 9.25458 9.25458 11.4499 6.5247 11.4499C3.79482 11.4499 1.5995 9.25458 1.59953 6.52473C1.59953 3.79485 3.79482 1.59956 6.5247 1.59956Z"
        fill="#999999"
      />
    </svg>
  );
}

MagnifyingGlass.defaultProps = {
  className: '',
};

export default MagnifyingGlass;
