import React, { ReactNode } from 'react';

import './list-item.css';

interface ListItemProps {
  className?: string;
  children?: ReactNode;
}

function CustomListItem({ className, children }: ListItemProps) {
  const listItemClass = `custom-list-item ${className || ''}`.trim();

  return (
    <li className={listItemClass}>
      {children}
    </li>
  );
}

CustomListItem.defaultProps = {
  className: '',
  children: true,
};

export default CustomListItem;
