import React from 'react';

import './button.css';

type ButtonType = 'submit' | 'reset' | 'button';

interface ButtonProps {
  description: string;
  type: ButtonType;
  handleClick: () => void;
  className?: string;
}

function Button({
  description,
  type,
  handleClick,
  className,
}: ButtonProps) {
  const buttonClass = className || 'button';

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={buttonClass} onClick={handleClick}>
      {description}
    </button>
  );
}

Button.defaultProps = {
  className: '',
};

export default Button;
