import React from 'react';

import './input-field.css';

interface InputProps {
  type: string;
  value: string | string[];
  isRequired: boolean;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

function Input({
  type,
  value,
  placeholder,
  isRequired,
  className,
  disabled,
  onChange,
  onKeyDown,
}: InputProps) {
  return (
    <input
      className={`input-field ${className}`}
      type={type}
      required={isRequired}
      value={value}
      autoCapitalize="none"
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
}

Input.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
  onKeyDown: () => {},
};

export default Input;
