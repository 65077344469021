import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import {
  loginUser,
  fetchUserData,
  editProfile,
  editAccount,
  postPhoneNumber,
  deletePhoneNumber,
  getListOfPrivateMobileNumbers,
} from './userAPI';
import { UserState } from './types';
import { Status } from '../../../utils/constants';
import { updatePartner } from '../partner/partnerSlice';

export function authorized() {
  return document.cookie.split('; ').some((item) => item.trim().startsWith('JWT_Token='));
}

export function destroyToken() {
  localStorage.removeItem('JWT_Token');
  document.cookie = 'JWT_Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { dispatch }) => {
    const response = await fetchUserData();
    const userData = response.data?.data;

    if (userData && userData.accounts && userData.accounts.length > 0) {
      const account = userData.accounts[0];

      if (account.is_switchboard_enabled === false) {
        const url = new URL(window.location.href);
        url.hostname = url.hostname.replace(/^switchboard/, 'app');
        url.pathname = '/';
        window.location.href = url.toString();
      }

      if (account.partner) {
        dispatch(updatePartner(account.partner));
      }
    }

    return userData;
  },
);

export const login = createAsyncThunk(
  'user/login',
  async ({ username, password }: { username: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await loginUser(username, password);
      const { JWT } = response.data;

      localStorage.setItem('JWT_Token', `JWT ${JWT}`);
      document.cookie = `JWT_Token=JWT ${JWT};`;

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editUserProfile = createAsyncThunk(
  'user/editProfile',
  async (
    { userId, profileData }: {
      userId: number;
      profileData: {
        username: string;
        firstName: string;
        lastName: string;
        personalEmail: string;
        mobileNo: string;
        emailAliases: string[];
      };
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await editProfile(userId, profileData);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || 'An unexpected error occurred');
    }
  },
);

export const editUserAccount = createAsyncThunk(
  'user/editAccount',
  async (complianceSms: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const account = state.user.data?.accounts[0];

    if (!account || !account.id) {
      return rejectWithValue('Account ID is missing or account data is not loaded');
    }

    const updatedAccountData = {
      ...account,
      compliance_sms: complianceSms,
    };

    try {
      const response = await editAccount(account.id, updatedAccountData);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || 'An error occurred');
    }
  },
);

export const getListOfPrivateNumbers = createAsyncThunk(
  'user/getPhoneNumbers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getListOfPrivateMobileNumbers();
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addPhoneNumber = createAsyncThunk(
  'user/addPhoneNumber',
  async (
    {
      phoneNumber,
      forceDeleteExisting,
    }: { phoneNumber: string; forceDeleteExisting: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = await postPhoneNumber(phoneNumber, forceDeleteExisting);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const removePhoneNumber = createAsyncThunk(
  'user/removePhoneNumber',
  async (phoneId: string, { rejectWithValue }) => {
    try {
      const response = await deletePhoneNumber(phoneId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState: UserState = {
  data: null,
  status: Status.Idle,
  errorMessage: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearErrorMessage: (userState) => {
      userState.errorMessage = null;
    },
    logout: (userState) => {
      userState.data = null;
      userState.status = Status.Idle;
      userState.errorMessage = null;

      destroyToken();
      window.location.reload();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (userState) => {
        userState.status = Status.Loading;
      })
      .addCase(fetchUser.fulfilled, (userState, action) => {
        userState.status = Status.Succeeded;
        userState.data = action.payload ? action.payload : null;
      })
      .addCase(fetchUser.rejected, (userState) => {
        userState.status = Status.Failed;
        userState.data = null;

        destroyToken();
        window.location.reload();
      })
      .addCase(login.pending, (userState) => {
        userState.errorMessage = null;
        userState.status = Status.Loading;
      })
      .addCase(login.fulfilled, (userState) => {
        userState.errorMessage = null;
        userState.status = Status.Succeeded;
      })
      .addCase(login.rejected, (userState, action) => {
        userState.status = Status.Failed;
        userState.errorMessage = { error: action.payload as string };
      })
      .addCase(editUserProfile.fulfilled, (userState, action) => {
        userState.data = action.payload.data;
      })
      .addCase(editUserProfile.rejected, (userState, action) => {
        userState.errorMessage = action.payload;
      })
      .addCase(editUserAccount.fulfilled, (userState, action) => {
        if (userState.data && userState.data.accounts && userState.data.accounts[0]) {
          userState.data.accounts[0].compliance_sms = action.payload.data.compliance_sms;
        }
      })
      .addCase(editUserAccount.rejected, (userState, action) => {
        userState.errorMessage = action.payload;
      })
      .addCase(getListOfPrivateNumbers.fulfilled, (userState, action) => {
        if (userState.data) {
          userState.data.private_phone_numbers = action.payload;
        }
      })
      .addCase(getListOfPrivateNumbers.rejected, (userState, action) => {
        userState.errorMessage = action.payload ? action.payload : 'Failed to fetch private phone numbers.';
      })
      .addCase(addPhoneNumber.fulfilled, (userState, action) => {
        if (userState.data && userState.data.private_phone_numbers) {
          userState.data.private_phone_numbers.push(action.payload);
        }
      })
      .addCase(addPhoneNumber.rejected, (userState, action) => {
        userState.errorMessage = action.payload;
      })
      .addCase(removePhoneNumber.fulfilled, (userState, action) => {
        if (userState.data && userState.data.private_phone_numbers) {
          const index = userState.data.private_phone_numbers.findIndex(
            (phone) => phone.id === action.meta.arg,
          );

          if (index !== -1) {
            userState.data.private_phone_numbers.splice(index, 1);
          }
        }
      })
      .addCase(removePhoneNumber.rejected, (userState, action) => {
        userState.errorMessage = action.payload;
      });
  },
});

export const { clearErrorMessage, logout } = userSlice.actions;

export default userSlice.reducer;
