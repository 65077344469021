import {
  SummaryData,
} from './types';

import {
  Info as RecordingInfo,
  RecordingUrlInfo,
  TranscriptDetails,
} from './models/types';

import axiosInstance from '../../../utils/axiosInstance';
import { Todo } from '../todos/types';

export const fetchRecordings = async (
  accountId: string,
  search?: string,
  userId?: number,
  sessionId?: string,
): Promise<RecordingInfo[]> => {
  const searchParams = new URLSearchParams();

  searchParams.append('recording_types[]', 'call'); // only fetch calls
  searchParams.append('limit', String(50)); // limit to 50 calls

  if (search) {
    searchParams.append('search', search);
  }

  if (userId) {
    searchParams.append('global_user_ids[]', String(userId));
  }

  if (sessionId) {
    searchParams.append('session_id', sessionId);
  }

  const query = searchParams.toString() ? `?${searchParams.toString()}` : '';
  const url = `accounts/${accountId}/stats/meetings${query}`;
  const response = await axiosInstance.get<RecordingInfo[]>(url);

  return response.data;
};

export const fetchTranscriptSummaries = async (
  sessionId: string,
): Promise<SummaryData> => {
  try {
    const response = await axiosInstance.get<SummaryData>(
      `recordings/sessions/${sessionId}/transcript/summaries`,
    );

    return response.data;
  } catch (e: any) {
    return e.message;
  }
};

export const fetchTodosBySession = async (
  accountId: string,
  sessionId?: string,
): Promise<Todo[] | undefined> => {
  try {
    const searchParams = new URLSearchParams();
    if (sessionId) {
      searchParams.append('sessionId', sessionId);
    }

    const query = searchParams.toString() ? `?${searchParams.toString()}` : '';
    const url = `account/${accountId}/categorization/todos${query}`;
    const response = await axiosInstance.get<Todo[]>(url);

    return response.data;
  } catch (e: any) {
    return e.message;
  }
};

export const fetchRecordingTranscript = async (
  sessionId: string,
): Promise<TranscriptDetails> => {
  const response = await axiosInstance.get<{ data: TranscriptDetails }>(
    `recordings/sessions/${sessionId}/transcript`,
  );

  return response.data.data;
};

export const fetchRecordingBySession = async (
  sessionId: string,
): Promise<RecordingUrlInfo> => {
  const response = await axiosInstance.get<{ data: RecordingUrlInfo }>(
    `recordings/sessions/${sessionId}`,
  );

  return response.data.data;
};

export const switchCallPrivacyType = async (
  sessionId: string,
): Promise<RecordingUrlInfo> => {
  const response = await axiosInstance.post<RecordingUrlInfo>(
    `sessions/${sessionId}/meetings/switch-to-business`,
  );

  return response.data;
};

export const deleteMeetingUsingSessionId = async (
  sessionId: string,
  accountId: string,
) => {
  const response = await axiosInstance.delete(
    `/accounts/${accountId}/stats/meetings/${sessionId}`,
  );

  return response.data;
};
