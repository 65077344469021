import React from 'react';

import './skeleton.css';

interface SkeletonProps {
  variant: string;
  width: string;
  height: number;
}

function Skeleton({ variant, width, height }: SkeletonProps) {
  const style = {
    width,
    height,
    borderRadius: variant === 'circular' ? '50%' : '8px',
    margin: variant === 'circular' ? '10px' : '',
  };

  return <div className="skeleton-loader" style={style} />;
}

export default Skeleton;
