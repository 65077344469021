import React from 'react';

import './recording-player-fallback.css';
import LoaderDots from '../../../ui/ui-feedback/LoaderDots';

function RecordingPlayerFallback() {
  return (
    <div className="recording-player-fallback">
      <LoaderDots />
    </div>
  );
}

export default RecordingPlayerFallback;
